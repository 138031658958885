@import '~styles/common';

.postDetails {
  background: $white;
  min-height: 100vh;
  padding: 12px 18px;

  @media #{$medium} {
    padding: 24px 32px;
  }
}

.postContent {
  background: $white;
  margin-bottom: 16px;
}

.postHeader {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 8px;

  @media #{$medium} {
    margin-bottom: 16px;
  }
}

.categoryLabel {
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontMedium;
  color: $brandGreen;
}

.authorInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  order: 2;

  @media #{$medium} {
    order: 1;
  }
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.authorMetadata {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.authorName {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $black;
  margin-bottom: 4px;
}

.timeAgo {
  font-family: $fontMain;
  font-size: $fontXxs;
  color: $mediumGray;
}

.postTitle {
  font-family: $fontMain;
  font-size: $fontL;
  font-weight: $fontRegular;
  color: $black;
  margin-bottom: 12px;
  line-height: 1.3;

  @media #{$medium} {
    font-size: $fontXl;
    margin-bottom: 16px;
    line-height: 1.4;
  }
}

.content {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 1.5;
  color: $black;
  margin-bottom: 16px;

  @media #{$medium} {
    line-height: 1.6;
    margin-bottom: 24px;
  }
}

.postActions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background: rgba($catskillWhite, 0.8);
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-family: $fontMain;
  font-size: $fontXs;
  color: $black;
  min-height: 32px;

  .icon {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    color: $slategray;
  }
}

.commentsSection {
  margin-top: 24px;
}

.addComment {
  margin-bottom: 24px;

  .addCommentButton {
    width: 100%;
    min-height: 25px;
    height: 30px;
    padding: 20px 25px;

    @media #{$medium} {
      width: auto;
    }
  }
}

.sortSection {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.sortLabel {
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $mediumGray;
}

.sortSelect {
  width: 150px;

  :global {
    .select__single-value {
      font-family: $fontSerif;
      font-size: $fontXs;
      color: $black;
      margin: 0;
    }

    .select__menu {
      font-family: $fontSerif;
      font-size: $fontXs;
    }

    .select__option {
      &:hover {
        background: rgba($brandGreen, 0.1);
      }

      &--is-selected {
        background: rgba($brandGreen, 0.1) !important;
        color: $black !important;
      }

      &--is-focused {
        background: rgba($brandGreen, 0.05);
      }
    }
  }
}

.commentsList {
  margin-top: 24px;
}
