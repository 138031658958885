@import '~styles/common';

.wrapper {
  composes: box gradientOverlay from '../common.module.scss';
  border: 1px solid $lightGray;
}

.list {
  composes: list from '../common.module.scss';
  padding-right: 20px;
}
