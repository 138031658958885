@import '~styles/common';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 22px 40px;
  font-size: $fontXs;
  font-weight: $fontMedium;
  line-height: 1;
  letter-spacing: 1px;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  background-color: $black;
  transition: color $buttonTransition, background-color $buttonTransition,
    border-color $buttonTransition;

  &:not(:last-child) {
    margin-bottom: 20px;

    @media #{$small} {
      margin: 0 20px 0 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $black;
    background-color: $brandGreen;
  }

  &.light {
    color: $black;
    background-color: $brandGreen;

    &:hover,
    &:focus,
    &:active {
      background-color: $darkGreen;
    }
  }

  &.warning {
    justify-content: center;
    background-color: $red;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: $darkRed;
    }
  }

  &:disabled {
    color: $cadetGray;
    background: $xLightGray;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    cursor: pointer;
  }
}

.outline {
  padding: 20px 38px;
  color: $black;
  background-color: transparent;
  border: 2px solid $brandGreen;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: $darkGreen;
  }

  &.light {
    color: $white;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }

  &:disabled {
    color: $lightGray;
    background-color: transparent;
    border-color: $xLightGray;
  }
}

.small {
  min-height: 50px;
  padding: 18px 40px;

  &.outline {
    padding: {
      top: 16px;
      bottom: 16px;
    }
  }
}

.block {
  width: 100%;
}

.icon {
  display: flex;
  align-items: center;
  height: 16px;
  margin: 0 -8px 0 18px;

  svg {
    height: 100%;
  }
}

.reversed {
  flex-direction: row-reverse;

  .icon {
    margin: 0 18px 0 -8px;
    transform: rotate(180deg);
  }
}

.processing {
  position: relative;
  color: transparent;

  &:disabled {
    color: transparent;
  }

  &:hover,
  &:focus,
  &:active {
    color: transparent;
  }

  &::before {
    @include spinner(18px, $dirtyWhite);

    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
  }
}
