@import '~styles/common';

.commentThread {
  position: relative;
  margin-bottom: 16px;

  &.nested {
    padding-left: 24px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 32px;
      bottom: 8px;
      width: 2px;
      background-color: rgba($mysticGray, 0.5);
    }
  }
}

.commentCard {
  position: relative;
  background: $white;
  padding: 8px 0;
}

.authorInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.authorMetadata {
  display: flex;
  align-items: center;
  gap: 6px;
}

.authorName {
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontRegular;
  color: $black;
}

.timeAgo {
  font-family: $fontMain;
  font-size: $fontXxs;
  color: $mediumGray;
}

.content {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 1.4;
  color: $black;
  margin-bottom: 8px;
  padding-right: 16px;
}

.commentActions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 4px;
}

.actionButton {
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $black;
  height: 24px;

  .icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    color: currentColor;
  }

  &:hover {
    color: $black;
  }
}

.showAllButton {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $brandGreen;
  cursor: pointer;
  margin-left: 8px;
}

.replies {
  position: relative;
  margin-top: 8px;
}
