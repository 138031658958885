@import '~styles/common';

.error {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontLight;
  color: $error;
}
