@import '~styles/common';

.categoryBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 20px;
  background: $white;
  cursor: pointer;

  @media #{$medium} {
    display: none;
  }
}

.categoryBarTitle {
  color: $slategray;
  font-size: $fontL;
  font-weight: $fontRegular;
}

.categoryBarIcon {
  color: $brandGreen;
  width: 12px;
  height: 12px;
  transform: rotate(-90deg);
}

.desktopCategoriesTitle {
  color: $mediumGray;
  font-size: $fontXl;
  font-family: $fontSerif;
  font-weight: $fontRegular;
  padding: 1.5rem;
}

.mobileCategories {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: $modalZIndex;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform $duration $spring;

  &.isOpen {
    transform: translateX(0);
  }

  @media #{$medium} {
    display: none;
  }

  .comprehensiveListMessage {
    padding: 0 20px 16px 24px;
    text-justify: auto;
    font-family: $fontSerif;
    font-size: $fontXs;
    font-weight: $fontLight;
    color: $slategray;

    .comprehensiveListBorder {
      border-top: 1px solid $xLightGray;
      width: 100%;
      margin: 0 auto 16px auto;
    }
  }
}

.mobileCategoriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.mobileCategoriesTitle {
  color: $slategray;
  font-size: $fontL;
  font-family: $fontSerif;
  font-weight: $fontRegular;
}

.closeButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    color: $black;
  }
}

.sidebar {
  display: none;

  @media #{$medium} {
    display: block;
    width: 350px;
    height: fit-content;
    flex-shrink: 0;
    background: $white;
  }
}

.categories {
  background: $white;
}

.categoryItem {
  &.expanded {
    .chevronIcon {
      transform: rotate(180deg);
    }

    .categoryButton {
      color: $brandGreen;
      border-left: 4px solid $brandGreen;
    }
  }
}

.categoryButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  background: none;
  border: none;
  border-left: 4px solid transparent;
  cursor: pointer;
  transition: all $duration $spring;

  &:hover {
    color: $brandGreen;
  }
}

.categoryName {
  font-family: $fontMain;
  font-size: $fontL;
  font-weight: $fontRegular;
  text-align: left;
}

.chevronIcon {
  width: 12px;
  height: 12px;
  color: currentColor;
  transition: transform $duration $spring;
  margin-left: 2rem;
}

.categoryContent {
  padding: 0 20px 16px 24px;
}

.categoryOpisTitle {
  color: $brandGreen;
  font-size: $fontXxs;
  font-weight: $fontMedium;
  font-family: $fontSerif;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.categoryDescription {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 1.5;
  color: $slategray;
  margin-bottom: 16px;
}

.comprehensiveListMessage {
  padding: 0 20px 16px 24px;
  text-justify: auto;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  color: $slategray;

  .comprehensiveListBorder {
    border-top: 1px solid $xLightGray;
    width: 100%;
    margin: 0 auto 16px auto;
  }
}
