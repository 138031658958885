@import '~styles/common';

$padding: 80px;
$smallPadding: $padding / 2;
$linkAnimation: 0.2s ease-in-out;
$height: 190px;

.photoSection {
  @include backgroundWithPhoto;

  padding: 60px 0;

  @media #{$medium} {
    padding: $padding * 2 0 $padding;
  }
}

.container {
  @include container;
}

.title {
  @include titleMain;

  font-size: 30px;
  color: $white;

  @media #{$medium} {
    font-size: $fontXxxl;
    line-height: 80px;
  }
}

.subtitle {
  max-width: 590px;
  margin: 20px 0 $smallPadding;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 19px;
  color: $white;

  @media #{$medium} {
    margin: $smallPadding 0 50px;
    font-size: 28px;
    font-weight: $fontMedium;
    line-height: 33px;
  }
}

.residenceContainer {
  padding-bottom: $smallPadding;
  background-color: $white;

  @media #{$medium} {
    padding: $padding * 2 0 $padding;
  }
}

.secondaryTitleCentered {
  @include titleMain;

  font-weight: $fontBold;

  @media #{$medium} {
    @include titleMainCenter;
  }
}

.cityContainer {
  display: grid;
  margin-top: 40px;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 275px);

  @media #{$medium} {
    justify-content: center;
  }

  @media #{$xlarge} {
    justify-content: flex-start;
    margin-top: $padding;
    grid-template-columns: repeat(4, 1fr);
  }
}

.cityPhoto {
  display: block;
  width: 275px;
  height: $height;
  object-fit: cover;
}

.cityDescription {
  position: relative;
  margin-bottom: 20px;
  padding: 20px 20px 0;
  font-size: $fontL;
  font-weight: $fontMedium;
  line-height: 1;

  @media #{$medium} {
    margin-bottom: 0;
    padding: 20px;
  }
}

.cityContacts {
  display: block;
  margin-top: 8px;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $cadetGray;
  transition: color $linkAnimation;
}

.cityLink {
  position: relative;
  color: $black;

  &::before {
    position: absolute;
    top: $height + 20px;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: $brandGreen;
    content: '';
    transition: top $linkAnimation, bottom $linkAnimation;

    @media #{$medium} {
      bottom: 20px;
    }
  }

  @media #{$medium} {
    transition: box-shadow $linkAnimation;

    &:hover,
    &:focus {
      box-shadow: 0 16px 28px 0 rgba($black, 0.1);

      &::before {
        top: $height;
        bottom: 0;
      }

      .cityContacts {
        color: $darkGreen;
      }
    }
  }
}

.addButtonIcon {
  @include square(30px);
}

.addButtonIconContainer {
  @include square(80px);

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  color: $white;
  background-color: $darkGreen;
  border-radius: 50%;
}

.addButton {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  font-size: $fontM;
  font-weight: $fontBold;
  line-height: 21px;
  cursor: pointer;

  @media #{$medium} {
    margin-top: 60px;
  }
}

.spinner {
  margin-top: 80px;
}
