@import '~styles/common';

.searchContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  gap: 16px;
}

.dropdownSelect {
  display: none;
  width: 200px;
  max-width: 200px;

  @media #{$medium} {
    display: block;
  }
}

.resultsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  grid-gap: 16px;

  @media #{$medium} {
    justify-content: flex-end;
    flex-direction: row;
  }
}

.comprehensiveListMessage {
  overflow-wrap: break-word;
}

.results {
  font-family: $fontRegular;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #00857d;
  text-transform: uppercase;
  white-space: nowrap;
}

.submit {
  display: none;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 60px;
  padding: 18px 22px;

  @media #{$medium} {
    display: flex;
    margin: 0 !important;
  }
}

.filterButtonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 6px;

  @media #{$medium} {
    justify-content: flex-start;
  }
}

.clearContainer {
  display: none;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  @media #{$medium} {
    display: flex;
  }
}

.clearContainerMobile {
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  @media #{$medium} {
    display: none;
  }
}

.trashIcon {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  color: #909fb7;
  fill: none;
}

.filterIconMobile {
  display: block;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;

  @media #{$medium} {
    display: none;
  }
}

.filterIcon {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  color: $xBlack;
  fill: none;

  @media #{$medium} {
    display: none;
  }
}

.clearText {
  margin-top: 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #909fb7;
  text-align: center;
  text-transform: uppercase;
}

.Modal_overlay__1TgzI {
  padding: 0 !important;
}

.modal {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: $white;
}

.modalForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 30px auto;
  gap: 16px;
}

.dropdownsWithButtonsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 75vh;
}

.dropdownsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.buttonsMobileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.closeIconContainer {
  width: 100%;
  margin-bottom: 50px;
  text-align: right;
}

.closeIcon {
  @include square(24px);

  margin-right: 2px;
  margin-bottom: 12px;
  color: $black;
  cursor: pointer;
}

.mobileSubmit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin: 0 !important;
  padding: 18px 22px;
}

.dropdownSelectMobile {
  display: block;
  width: 100%;
}

.cancelButton {
  margin: 12px auto;
  font-size: 16px;
  cursor: pointer;
}
