@import '~styles/common';

.title {
  composes: gradientOverlay from '../common.module.scss';
  padding: 40px 40px 5px;
  font-size: $fontS;
  text-transform: uppercase;

  &::after {
    bottom: -40px;
    height: 40px;
    background: linear-gradient($dirtyWhite, transparent);
  }
}

.highlighted {
  color: $darkGreen;
}
