@import '~styles/common';

.replyForm {
  margin-top: 16px;
  padding: 16px;
  border: 2px solid $mysticGray;
  background: $white;
}

.replyInput {
  position: relative;
}

.replyingTo {
  position: absolute;
  top: 8px;
  left: 8px;
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $slategray;
}

.commentTextarea {
  width: 100%;
  min-height: 100px;
  padding: 32px 8px 8px;
  font-family: $fontSerif;
  font-size: $fontXs;
  border: none;
  resize: vertical;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $lightGray;
    font-family: $fontSerif;
    font-weight: $fontLight;
  }
}

.replyFormActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.cancelButton {
  padding: 8px 16px;
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $mediumGray;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: $black;
  }
}

.submitButton {
  padding: 8px 16px;
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $white;
  background: $brandGreen;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: $darkGreen;
  }
}
