@import '~styles/common';

.expertise {
  display: inline-block;
  width: auto;
}

.expertiseTag {
  padding: 10px 14px 10px 20px;
}

.button {
  @include iconBtnHoverEffect(24px);

  margin-left: 14px;
}

.icon {
  @include square(10px);

  position: relative;
}
