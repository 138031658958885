@import '~styles/common';

$defaultPadding: 20px;
$navButtonHeight: 40px;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  padding: $defaultPadding;
  color: $black;
  background-color: $white;
  border: 2px solid transparent;
  border-bottom: 8px solid transparent;
  transition: border-color 0.2s ease-in-out;
  place-content: space-between;

  &:hover {
    border-color: $brandGreen;
  }

  @media #{$medium} {
    flex-direction: row;
    padding: $defaultPadding * 2 $defaultPadding * 2 32px;
  }
}

.grantDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grantTitle {
  @include titleSecondary;

  width: fit-content;
}

.eligibilityContainer {
  @include paragraph;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5em;

  @media #{$medium} {
    flex-direction: row;
  }
}

.eligibilitySpan {
  margin-top: 12px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: $brandGreen;

  @media #{$medium} {
    margin-top: 0;
    padding: 12px 0;
  }
}

.eligibility {
  @include paragraph;

  padding: 0;
  line-height: 1.2em;

  @media #{$medium} {
    padding: 12px 0;
  }
}

.stageAndLocationContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
  }
}

.country {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
  max-height: 2em;
  margin-bottom: 12px;
  overflow: hidden;
  line-height: 1.2em;
  gap: 4px;

  @media #{$medium} {
    max-width: 50%;
  }
}

.countryElements {
  position: relative;
  width: fit-content;
  overflow: hidden;
  font-family: $fontMain;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: $black;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.icon {
  @include square(16px);

  margin-right: 2px;
  color: $brandGreen;
}

.countryIconContainer {
  width: 20px;
}

.linkContainer {
  text-align: right;
}

.linkButton {
  min-height: $navButtonHeight;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 30px;

  &:hover {
    color: $black;
  }
}

.linkIcon {
  @include square(20px);

  fill: $dirtyWhite;
}

.stage {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  max-width: 100%;
  margin-bottom: 12px;
  line-height: 1.2em;
  gap: 4px;
}

.stageName {
  width: fit-content;
  margin-top: -1px;
  font-family: $fontSerif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: $brandGreen;
  white-space: nowrap;
}

.stageElements {
  position: relative;
  width: fit-content;
  overflow: hidden;
  font-family: $fontSerif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: $black;
}

.stageRectangle {
  position: relative;
  width: 1px;
  height: 19px;
  background-color: #a5b2c6;
}

.institution {
  display: -webkit-box;
  width: fit-content;
  margin-bottom: 12px;
  overflow: hidden;
  font-family: $fontSerif;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  color: $black;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.showAllButton {
  padding: 4px 0;
  font-size: 14px;
  font-weight: 400;
  color: $brandGreen;
  cursor: pointer;
}

.modalContentWrapper {
  max-width: 600px;
}

.closeIconContainer {
  width: 100%;
  text-align: right;
}

.closeIcon {
  @include square(28px);

  margin-right: 2px;
  margin-bottom: 12px;
  color: $black;
  cursor: pointer;
}

.buttonContainer {
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fullDescriptionButtonContainer {
  width: 100%;

  @media #{$medium} {
    width: 50%;
  }
}

.fullDescriptionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 12px auto;
  font-size: 16px;

  @media #{$medium} {
    width: auto;
  }
}

.fullDescriptionIcon {
  @include square(28px);

  fill: $dirtyWhite;
}

.cancelButton {
  margin: 12px auto;
  font-size: 16px;
  cursor: pointer;
}

.callStartAtEndAtElementContainer {
  @include paragraph;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 12px;
  line-height: 30px;
  gap: 0.5em;

  @media #{$medium} {
    flex-direction: row;
  }
}

.callStartAtEndAtElementName {
  font-size: 14px;
  font-weight: 400;
  color: $brandGreen;

  @media #{$medium} {
    margin-top: 0;
    padding: 12px 0;
  }
}
