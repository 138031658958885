@import '~styles/common';

.form {
  composes: authLayoutContent from '../common.module.scss';
}

.names {
  @media #{$medium} {
    display: flex;
  }
}

.firstName {
  @media #{$medium} {
    width: 40%;
    margin-right: 25px;
  }
}

.lastName {
  flex-grow: 1;
  width: auto;
}

.button {
  margin-top: 20px;
}
