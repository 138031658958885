@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin spinner($size, $color) {
  width: $size;
  height: $size;
  margin-top: -($size/2);
  margin-left: -($size/2);
  border: 2px solid $color;
  border-top-color: transparent;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

@mixin iconBtnHoverEffect($size) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;

  &::before {
    @include square($size);

    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba($black, 0.05);
    border-radius: 50%;
    content: '';
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity $duration $spring;
  }

  &:hover,
  &:focus,
  &:active {
    &::before {
      opacity: 1;
    }
  }
}
