@import '~styles/common';

.orderDropdownWrapper {
  display: flex;
  align-items: center;
  height: 34px;
  margin-bottom: 8px;

  @media #{$medium} {
    margin-bottom: 0;
  }
}
