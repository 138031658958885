@import '~styles/common';

.section {
  @include heroSection;
}

.container {
  @include container;

  position: relative;
}

.row {
  @include row;
}

.heroTitle {
  @include titleMainLanding;
}

.heroParagraph {
  @include paragraphLanding;
}

.textBolder {
  @include paragraphBold;
}

.halfWidthContainer {
  @include halfWidthContainer;
}

.heroPhoto {
  @include photoLanding;
}
