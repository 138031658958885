@import '~styles/common';

.container {
  --date-selected-color: #20dacc;
  --date-selected-hover-opacity: rgba(32, 218, 204, 0.1);
  --error-background: #FEF2F2;
  --error-border: #EF4444;

  &.hasError {
    :global(.react-datepicker__input-container) {
      input {
        background-color: var(--error-background);
        border-color: var(--error-border);

        &:focus {
          background-color: var(--error-background);
          border-color: var(--error-border);
        }

        &:not(:placeholder-shown) {
          background-color: var(--error-background);
          border-color: var(--error-border);
        }
      }
    }
  }

  :global(.react-datepicker-popper) {
    z-index: 10;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container) {
    input {
      height: 50px;
      margin-bottom: 20px;
      font-family: $fontSerif;
      font-size: 14px;
      font-weight: 300;
      background-color: $dirtyWhite;
      width: 100%;
      padding: 10px;
      border: 1px solid rgb(185, 199, 220);
      border-radius: 2px;
      color: #6B7280;
      transition: all 0.2s ease-in-out;

      &:not(:placeholder-shown) {
        border: 1px solid #20dacc;
        background: #f2fdfc;
      }

      &:focus {
        background: #f2fdfc;
        border: 1px solid #20dacc;
        outline: none;
      }

      &::placeholder {
        font-family: $fontSerif;
        font-size: 14px;
        font-weight: 300;
        color: $mediumGray
      }
    }
  }

  :global(.react-datepicker) {
    font-family: $fontSerif;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 16px;
  }

  :global(.react-datepicker__header) {
    background-color: white;
    border-bottom: none;
    padding-top: 0;
  }

  :global(.react-datepicker__current-month) {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    padding: 8px 0;
    margin-bottom: 8px;
  }

  :global(.react-datepicker__day-names) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  :global(.react-datepicker__day-name) {
    color: #6B7280;
    font-size: 12px;
    width: 36px;
    margin: 0;
  }

  :global(.react-datepicker__month) {
    margin: 0;
  }

  :global(.react-datepicker__week) {
    display: flex;
    justify-content: space-between;
  }

  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--keyboard-selected) {
    background-color: var(--date-selected-color);
    color: white;
    border-radius: 50%;

    &:hover {
      background-color: var(--date-selected-color);
    }
  }

  :global(.react-datepicker__day) {
    margin: 0;
    padding: 8px;
    width: 36px;
    height: 36px;
    line-height: 20px;
    border-radius: 50%;
    color: #374151;
    font-size: 14px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: var(--date-selected-hover-opacity);
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__navigation) {
    top: 16px;

    &--previous {
      left: 16px;
    }

    &--next {
      right: 16px;
    }
  }

  :global(.react-datepicker__navigation-icon) {
    &::before {
      border-color: #6B7280;
      border-width: 2px 2px 0 0;
    }
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  .label {
    margin-bottom: 8px;
    font-family: $fontSerif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #000;
    white-space: nowrap;
  }
}

.clearContainer {
  display: none;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  @media #{$medium} {
    display: flex;
  }
}

.clearText {
  margin-top: 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #909fb7;
  text-align: center;
  text-transform: uppercase;
}

.trashIcon {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  color: #909fb7;
  fill: none;
}

.errorMessage {
  color: var(--error-border);
  font-family: $fontSerif;
  font-weight: $fontLight;
  font-size: 12px;
  margin-top: -16px;
  margin-bottom: 16px;
}
