@import '~styles/common';

.showButton {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  font-weight: $fontRegular;
  text-transform: none;
  background-color: $white;

  svg {
    @include square(10px);

    color: $brandGreen;
  }

  @media #{$medium} {
    font-size: $fontXs;
    line-height: 14px;
  }
}

.aboutText {
  position: relative;
  overflow: hidden;
  font-family: $fontSerif;
  font-weight: $fontLight;
  line-height: 24px;

  &.truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  &.fullHeight {
    -webkit-line-clamp: inherit;
  }
}

.boxText {
  composes: boxText from '../common.module.scss';
}

.textarea {
  width: 100%;
  height: 180px;
  margin-bottom: 8px;
  padding: 20px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 24px;
  background-color: $dirtyWhite;
  border: 1px solid $lightGray;
  resize: none;

  &::placeholder {
    color: $mediumGray;
  }
}

.counter {
  float: right;
  font-size: $fontS;
  line-height: 19px;
  color: $lightGray;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 60px;

  @media #{$xsmall} {
    flex-wrap: nowrap;
  }
}

.button {
  min-width: 100%;

  @media #{$xsmall} {
    min-width: 180px;
  }
}

.submit {
  composes: button;

  @media #{$xsmall} {
    margin-right: 20px;
  }
}

.cancel {
  composes: button;
  margin-bottom: 20px;

  @media #{$small} {
    margin-bottom: 0;
  }
}
