@import '~styles/common';

.list {
  @media #{$xsmall} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media #{$small} {
    justify-content: center;
  }
}

.whiteBoxContainer {
  @media #{$small} {
    margin-top: 30px;
  }

  @media #{$medium} {
    margin-top: 0;
  }
}

.container {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 30px;

    @media #{$xsmall} {
      margin-top: 0;
      margin-left: 30px;
    }

    @media #{$small} {
      margin-left: 90px;
    }
  }
}

.icon {
  @include square(64px);

  margin-right: 30px;
}

.title {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 20px;

  @media #{$small} {
    margin-bottom: 4px;
  }
}

.stats {
  font-size: $fontXl;
  font-weight: $fontBlack;
  line-height: 52px;

  @media #{$small} {
    font-size: 50px;
  }
}
