@import '~styles/common';

.section {
  @include backgroundWithPhoto;

  padding: 40px 20px;
  color: $white;

  @media #{$medium} {
    padding: 100px 20px;
  }
}

.title {
  max-width: 790px;
  margin: 0 auto 28px;
  margin-bottom: 28px;
  font-size: $fontXl;
  font-weight: $fontBold;
  font-weight: $fontBlack;
  line-height: 28px;
  text-align: center;

  @media #{$medium} {
    margin-bottom: 40px;
    font-size: $fontXxl;
    line-height: 60px;
  }
}

.titleHighlighted {
  color: $brandGreen;
}

.content {
  max-width: 790px;
  margin: 0 auto;
}
