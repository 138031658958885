@import '~styles/common';

.container {
  @include container;

  padding-bottom: 60px;

  @media #{$medium} {
    padding: 80px 20px;
  }
}

.title {
  @include titleMain;
}

.subtitle {
  @include subtitle;

  margin-bottom: 40px;
}
