@import '~styles/common';

.form {
  composes: authLayoutContent from '../common.module.scss';
}

.list {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 26px;
}

.listItem {
  position: relative;
  padding-left: 16px;

  &::before {
    position: absolute;
    left: 0;
    content: '\2014';
  }
}

.submit {
  min-width: 180px;
  margin-top: 40px;
}
