@import '~styles/common';

.messageLeft {
  display: flex;
  justify-content: flex-start;
}

.messageRight {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.message {
  margin: 5px 0;
  padding: 10px;
  color: $white;
  text-align: left;
  background-color: $black;
  border-radius: 10px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}
