@import '~styles/common';

.section {
  padding: 0 0 60px;

  @media #{$small} {
    padding: 100px 0 160px;
  }
}

.container {
  @include container;

  display: flex;
  flex-direction: column;

  @media #{$small} {
    align-items: center;
  }
}

.paragraphCentered {
  @include paragraphLanding;

  max-width: 680px;

  @media #{$small} {
    text-align: center;
  }
}

.title {
  @include titleMain;

  @media #{$small} {
    @include titleMainCenter;
  }

  @media #{$medium} {
    @include titleMainCenter;

    padding-bottom: 64px;
  }
}

.bold {
  font-weight: $fontRegular;
}
