@import '~styles/common';

.clearableLabel {
  display: flex;
  align-items: center;
  place-content: space-between;
}

.clearFiltersButton {
  font-size: $fontXxs;
  color: $black;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    color: $mediumGray;
    cursor: default;
  }
}

.clearIcon {
  margin-right: 4px;
}
