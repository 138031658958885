@import '~styles/common';

$spacing: 16px;

.tag {
  display: inline-flex;
  align-items: center;
  margin: 0 $spacing $spacing 0;
  padding: 10px 20px;
  font-size: $fontXxxs;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: $mysticGray;
  border-radius: 2px;

  &:first-of-type {
    margin-top: $spacing;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
