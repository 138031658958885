@import '~styles/common';

.section {
  position: relative;

  @media #{$small} {
    margin: 80px 0 0;
  }
}

.container {
  @include container;
  @include row;

  position: relative;
}

.title {
  @include titleMain;

  @media #{$small} {
    margin-top: 80px;
  }
}

.subtitle {
  @include subtitle;
}

.halfWidthContainer {
  @include halfWidthContainer;
}

.featureTitle {
  margin-bottom: 20px;
  font-family: $fontSerif;
  font-size: $fontL;
  font-weight: $fontMedium;
  line-height: 30px;
}

.featureText {
  max-width: 384px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 30px;
}

.featureContainer {
  position: relative;
  padding: 40px 0;

  &:not(:first-of-type) {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 2px;
      background-color: $brandGreen;
      content: '';
    }
  }
}

.colorBg {
  position: absolute;
  top: 195px;
  left: 0;
  width: 100%;
  height: 230px;
  background-color: $brandGreen;

  @media #{$small} {
    top: 0;
    width: calc(50% - 100px);
    height: 600px;
  }
}

.photo {
  max-width: 60%;
  box-shadow: $boxShadowPhoto;
}

.photo2 {
  position: absolute;
  right: 0;
  bottom: 55px;
  max-width: calc(100% - 65px);

  @media #{$small} {
    right: auto;
    bottom: 320px;
    left: 50px;
  }
}

.photoContainer {
  @include halfWidthContainer;

  position: relative;
  margin: 60px auto 0;

  @media #{$small} {
    margin: 120px 0 0;
  }
}

.mobile {
  @media #{$small} {
    display: none;
  }
}

.desktop {
  display: none;

  @media #{$small} {
    display: block;
  }
}
