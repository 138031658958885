@import '~styles/common';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: $fontSerif;
  font-size: $fontM;
  font-weight: $fontMedium;
  line-height: 24px;
}

.paragraph {
  max-width: 680px;
  margin-top: 20px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 24px;

  &:last-of-type {
    margin-bottom: 40px;
  }
}

.button {
  min-width: 180px;
  margin-left: auto;
}
