@import '~styles/common';

.pageWrapper {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 79px;

  @media #{$medium} {
    flex-direction: row;
    margin-top: 80px;
  }
}

.leftContainer {
  flex-grow: 1;
  min-height: 0;
  margin: 20px;
  padding: 20px;
  background: $white;

  @media #{$medium} {
    max-width: 400px;
    min-width: 400px;
    min-height: 70vh;
    margin: 20px 10px 20px 20px;
    overflow-y: auto;
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 11;
  min-height: 70vh;
  margin: 20px;
  padding: 20px 20px 0 20px;
  background: $white;

  @media #{$medium} {
    margin: 20px 20px 20px 10px;
  }
}

.spinnerContainer {
  min-height: 100vh;
}
