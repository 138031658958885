@import '~styles/common';

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.buttonWrapper {
  position: relative;

  @media #{$small} {
    @include container;
  }
}

.button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transform: translateX(50%);

  @media #{$small} {
    bottom: 0;
    left: 140px;
  }
}

.icon {
  width: 40px;
  height: 20px;
}

.prev {
  transform: translateX(-150%) rotate(180deg);

  @media #{$small} {
    left: 20px;
    transform: rotate(180deg);
  }
}
