@import '~styles/common';

.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 26px;
}

.label {
  @include label;
}

.prompt {
  display: block;
  margin: -8px 0 11px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: $line-height;
}

.inputWrapper {
  position: relative;
}

.input {
  @include input;

  display: block;
  width: 100%;
}

.valid {
  @include inputValid;
}

.invalid {
  @include inputInvalid;
}
