@import '~styles/common';

.row {
  @include row;
}

.partnerContainer {
  @include halfWidthContainer;

  margin-top: 40px;

  @media #{$medium} {
    margin-top: 80px;

    &:nth-of-type(odd) {
      padding-right: 65px;
    }

    &:nth-of-type(even) {
      padding-left: 65px;
    }
  }
}

.featureTitle {
  @include titleSecondary;

  padding-left: 28px;
  font-family: $fontSerif;
  font-size: $fontL;

  &::before {
    width: 8px;
  }

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.featureSubTitle {
  @include titleSecondary;

  min-height: 40px;
  padding-left: 28px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-style: italic;
  font-weight: $fontLight;

  &::before {
    width: 8px;
  }

  @media #{$medium} {
    font-size: $fontM;
  }
}

.image {
  max-width: 50%;
  margin-top: 20px;
}

.image2 {
  max-width: 70%;
  margin-top: 10%;
}

.imageContainer {
  text-align: center;
}
