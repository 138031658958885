@import '~styles/common';

.topContainer {
  @include backgroundWithPhoto;

  padding: 80px 0 180px;

  @media #{$medium} {
    padding: 180px 0 240px;
  }
}

.mainTitle {
  @include titleMain;

  color: $white;
}

.container {
  @include container;

  @media #{$xlarge} {
    padding: 0 200px;
  }
}

.checkboxTitle {
  margin-bottom: 16px;
  font-size: $fontS;
  font-weight: $fontRegular;
  line-height: 19px;
}

.mainSection {
  padding-bottom: 80px;
  background-color: $lightBlue;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media #{$small} {
    margin-left: auto;
  }
}

.button {
  width: 100%;
  min-width: 180px;

  @media #{$small} {
    width: auto;
  }
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.form {
  display: flex;
  flex-direction: column;

  .buttonContainer {
    margin-top: 20px;
  }
}

.inputsContainer {
  position: relative;
  margin-top: 40px;
  padding-top: 5px;
}

.label {
  font-size: $fontS;
  line-height: 19px;
  border-bottom: 1px solid $lightGray;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
}

.input {
  display: block;
  width: 100%;
  padding: 20px 0;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 19px;
}

.changeEmailInfo {
  margin: -10px 0 5px;
  font-size: $fontXs;
  line-height: 16px;
  color: $error;
}

.socialMediaInfo {
  margin: -10px 0 5px;
  font-size: $fontXs;
  line-height: 16px;
}

.email {
  font-weight: $fontBold;
}
