@import '~styles/common';

.name {
  flex-grow: 1;
  padding: 18px;
  overflow: hidden;
  font-size: $fontXs;
  line-height: 1;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $jaki;
}

.iconWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: $black;
  transition: background-color $duration $spring;

  &.open {
    background-color: $brandGreen;
  }
}

.icon {
  height: 18px;
  margin-right: 8px;
}

.arrow {
  height: 6px;
  color: $white;
  transition: transform $duration $spring;

  &.open {
    transform: rotate(180deg);
  }
}

.button {
  display: none;
  align-items: center;
  width: 258px;
  margin-left: 34px;
  cursor: pointer;

  &:hover {
    .iconWrapper {
      background-color: $brandGreen;
    }
  }

  @media #{$medium} {
    display: flex;
  }
}
