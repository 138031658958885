@import '~styles/common';

.fieldWrapper {
  padding: 28px;
  cursor: pointer;
  border: 1px dashed $mediumGray;
}

.fileHeader {
  margin-top: 20px;
  font-size: $fontXs;
  font-weight: $fontBold;
  text-transform: uppercase;
}

.file {
  margin-top: 10px;
  font-style: italic;
  word-break: break-all;
}
