@import '~styles/common';

.userDetails {
  margin-top: 10px;
  font-size: $fontXxs;
  font-weight: $fontBold;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: $black;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }

  @media #{$medium} {
    font-size: $fontS;
  }
}

.position {
  margin-right: $smallSpacing;
  color: $darkGreen;

  @media #{$xsmall} {
    margin-right: 12px;
    padding-right: 10px;
    border-right: 1px solid $cadetGray;
  }

  @media #{$medium} {
    margin-right: 18px;
    padding-right: 20px;
  }
}

.university {
  display: inline-flex;

  @media #{$xsmall} {
    display: inline;
  }
}

.location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $smallSpacing;
  font-size: $fontXs;
  font-weight: $fontRegular;
  line-height: 19px;
  letter-spacing: 1px;
  color: $black;

  @media #{$xsmall} {
    margin-top: 30px;
  }

  @media #{$medium} {
    font-size: $fontS;
  }
}

.residenceLabel {
  display: flex;
  align-items: center;
  padding-right: 5px;
  font-weight: $fontBold;
  color: $darkGreen;
}

.icon {
  width: 12px;
  height: 16px;
  margin-right: 12px;
}
