@import '~styles/common';

.tag {
  margin: 8px;

  &:first-of-type,
  &:last-of-type {
    margin: 8px;
  }
}

.button {
  @include iconBtnHoverEffect(24px);

  margin-left: 14px;
}

.icon {
  @include square(10px);
}
