@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~../../../../assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~../../../../assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 80px 40px;
  color: white;

  @media #{$medium} {
    max-width: 45%;
    margin-left: 120px;
    padding: 160px 0 20px;
  }
}

.headingTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.headingSubtitle {
  padding-bottom: 20px;
  font-size: $fontL;

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.headingDescription {
  @include paragraph;

  padding-bottom: 80px;
}

.pageTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.pageSubtitle {
  @include paragraph;

  padding-bottom: 40px;
  font-size: $fontXs;

  @media #{$medium} {
    max-width: 60%;
    padding-bottom: 80px;
    font-size: $fontMedium;
  }
}

.emphasized {
  padding: 0 5px;
  font-weight: $fontMedium;
}

.searchP {
  font-family: $fontRegular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #000;
}

.stepTitle {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: $fontBold;
}

.stepContent {
  font-family: $fontMain;
  font-size: 14px;
  font-weight: $fontRegular;
}

.events {
  @include container;

  margin-top: 40px;

  @media #{$medium} {
    min-height: 100vh;
    margin-top: 60px;
  }
}

.eventsWithFiltersContainer {
  display: flex;
  flex-direction: column-reverse;

  @media #{$medium} {
    flex-direction: column;
  }
}

.eventsList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
}


.eventsPagination {
  width: 90%;
  margin: 26px auto;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.addEventButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media #{$medium} {
    width: auto;
  }
}

.addEventButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 90px;
  width: 100%;
  padding: 18px 32px;
  font-size: 16px;
  text-wrap: nowrap;

 @media #{$medium} {
   width: auto;
 }
}

.resultsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: -25px;
  margin-bottom: 20px;
  grid-gap: 16px;

  @media #{$medium} {
    margin-top: 20px;
    justify-content: flex-start;
    flex-direction: row;
  }
}

.results {
  font-family: $fontRegular;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #00857d;
  text-transform: uppercase;
  white-space: nowrap;
}
