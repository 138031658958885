@import '~styles/common';

.pageWrapper {
  @include container;

  margin-top: 72px;

  @media #{$medium} {
    margin-top: 148px;
  }
}

.title {
  @include titleMain;

  @media #{$medium} {
    margin-bottom: 24px;
  }
}

.paragraph {
  @include paragraph;

  font-size: $fontXs;

  &:first-of-type {
    padding-bottom: 36px;
  }

  @media #{$medium} {
    font-size: $fontS;
  }
}

.statementTitle {
  @include titleSecondary;

  margin: 48px 0 32px;
  font-size: $fontM;

  @media #{$medium} {
    font-size: $fontXl;
  }
}
