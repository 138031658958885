$spring: cubic-bezier(0.17, 0.67, 0.47, 1);
$shortDuration: 0.2s;
$duration: 0.3s;

$buttonTransition: 0.25s ease-in-out;

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-8px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}
