// layout
@mixin photoLanding {
  width: 100%;
  height: 400px;

  background: {
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @media #{$small} {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - #{$landingCentralSpace});
    height: 100%;
    margin-left: $landingCentralSpace;

    background: {
      position: top left;
      size: contain;
    }
  }
}

//do-to: to compose padding-top value from some navbarHeight constant + padding?
@mixin heroSection {
  @media #{$small} {
    position: relative;
    display: flex;
    padding: 100px 20px 40px;
  }

  @media #{$medium} {
    min-height: 100vh;
    padding: 240px 20px 40px;
  }
}

// typography
@mixin titleMainLanding {
  @include titleMain;

  margin: 60px 0 28px;
  font-size: 26px;
  font-weight: $fontBlack;
  line-height: 30px;

  @media #{$medium} {
    margin: 0 0 80px;
    font-size: $fontXxxl;
    line-height: 80px;
  }
}

@mixin paragraphLanding {
  @include paragraph;

  max-width: 490px;
  margin-bottom: 30px;

  @media #{$medium} {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 60px;
    }
  }
}
