$fontMain: 'Roboto', sans-serif;
$fontSerif: 'Roboto Slab', serif;

$fontXxxs: 10px;
$fontXxs: 12px;
$fontXs: 14px;
$fontS: 16px;
$fontM: 18px;
$fontL: 20px;
$fontXl: 24px;
$fontXxl: 40px;
$fontXxxl: 60px;

$fontLight: 300;
$fontRegular: 400;
$fontMedium: 500;
$fontBold: 700;
$fontBlack: 900;

@mixin titleMain {
  position: relative;
  margin: 60px 0 28px;
  padding-top: 20px;
  font-size: 26px;
  font-weight: $fontBold;
  font-weight: $fontBlack;
  line-height: 30px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: $brandGreen;
    content: '';
    transform: translateX(0);
  }

  @media #{$medium} {
    margin: 0;
    font-size: $fontXxl;
    line-height: 60px;
  }
}

@mixin titleMainCenter {
  @include titleMain;

  text-align: center;

  &::before {
    left: 50%;
    transform: translateX(-50%);
  }
}

@mixin titleSecondary {
  padding-left: 24px;
  font-size: $fontXl;
  border-left: 2px solid $brandGreen;
}

@mixin subtitle {
  font-size: $fontS;
  font-weight: $fontMedium;
  color: $darkGreen;
  text-transform: uppercase;

  @media #{$medium} {
    margin-top: 20px;
  }
}

@mixin paragraph {
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 24px;

  @media #{$medium} {
    font-size: $fontM;
    line-height: 30px;
  }
}

@mixin paragraphBold {
  font-weight: $fontMedium;
}
