@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 150px 40px 80px;
  color: white;

  @media #{$medium} {
    margin-left: 120px;
  }
}

.spinnerContainer {
  min-height: 100vh;
}

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-top: 40px;

  @media #{$medium} {
    flex-direction: row;
    margin-top: 60px;
  }
}

.jobOfferWrapper {
  @include container;
}

.pageTitle {
  @include titleMain;

  margin: 50px 0;

  @media #{$medium} {
    margin: 60px 0;
  }
}

.jobOfferInstitution {
  @include paragraph;

  padding-bottom: 40px;
  font-size: $fontXs;

  @media #{$medium} {
    font-size: $fontMedium;
  }
}

.infoArea {
  flex-grow: 1;
  width: 100%;
  margin-right: 24px;
  margin-bottom: 40px;
}

.detailsArea {
  width: 100%;
  margin-bottom: 24px;
  padding: 20px;
  background-color: $white;

  @media #{$medium} {
    width: 50%;
    padding: 40px;
  }
}

.descriptionContent {
  @include paragraph;

  margin-bottom: 16px;
  font-size: $fontXs;

  @media #{$medium} {
    font-size: $fontS;
  }
}

.cardHeader {
  @include titleSecondary;
}

.margined {
  margin-bottom: 40px;
}

.descriptionHeading {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-bottom: 40px;
  place-content: space-between;

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
  }
}

.jobOfferCreatedAt {
  display: flex;
  margin-bottom: 24px;
  font-weight: $fontMedium;

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.icon {
  @include square(16px);

  margin-right: 8px;
  color: $brandGreen;
}

.bold {
  font-weight: $fontBold;
}

.contactEmail {
  @include subtitle;

  font-weight: $fontBold;
  text-transform: none;
}

.footerNavigation {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 48px;

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
    place-content: space-between;
  }
}

.backLink {
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: $black;
  text-transform: uppercase;
}

.scrollTopButton {
  margin-bottom: 24px;

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.arrowIcon {
  width: 40px;
  height: 20px;
  margin-right: 18px;
  transform: rotate(180deg);
}

.white {
  color: $white;
}

.summaryItem {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.applyHeader {
  composes: cardHeader;
  margin-bottom: 40px;
}

.boldDescription {
  composes: descriptionContent;
  composes: bold;
}
