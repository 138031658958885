@import '~styles/common';

$listMobileHeight: 360px;
$listDesktopHeight: 460px;
$buttonHeight: 60px;
$borderWidth: 1px;

.wrapper {
  composes: box gradientOverlay from '../common.module.scss';
  display: flex;
  flex-direction: column;
  border: $borderWidth solid $lightGray;

  &::after {
    bottom: $buttonHeight;
    height: 40px;
  }
}

.list {
  composes: list from '../common.module.scss';
  padding-top: 25px;
  padding-right: 10px;
  overflow-y: visible;
  border: none;
}

.button {
  justify-content: flex-end;
  width: 100%;
  padding: 22px;
  padding-left: 40px;
  color: $black;
  background: $mysticGray;

  &:hover,
  &:focus,
  &:active {
    background: $mysticDarkGray;
  }

  @media #{$medium} {
    padding: 22px 30px;
  }

  @media #{$large} {
    padding: 22px 40px;
  }
}

.container {
  flex: 1;
  overflow-y: auto;
}
