@import '~styles/common';

$basicSpace: 40px;

.section {
  position: relative;

  @media #{$medium} {
    margin: 80px 0 0;
  }
}

.container {
  @include container;
}

.title {
  @include titleMain;
}

.halfWidthContainer {
  @include halfWidthContainer;
}

.row {
  @include row;
}

.icon {
  @include square(64px);
}

.statsTitle {
  margin: 20px auto 15px;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 19px;
  text-align: center;
}

.stats {
  font-size: $fontXxl;
  font-weight: $fontBlack;
  line-height: 40px;
  text-align: center;
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.statContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: $basicSpace;
  padding-top: $basicSpace;

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    width: $basicSpace;
    height: 1px;
    background-color: $mysticGray;
    content: '';
    transform: translateX(-50%);
  }

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;

    &::after {
      opacity: 0;
    }
  }

  @media #{$xsmall} {
    width: 50%;
    margin-top: $basicSpace;

    &::after {
      top: 50%;
      left: 0;
      width: 1px;
      height: $basicSpace;
      opacity: 1;
      transform: translate(0, -50%);
    }

    &:first-of-type,
    &:nth-of-type(2) {
      margin-top: 0;
      padding-top: 0;
    }

    &:nth-of-type(odd) {
      &::after {
        opacity: 0;
      }
    }
  }

  @media #{$medium} {
    width: 25%;

    &::after {
      width: 1px;
      opacity: 0;
    }

    &:not(:first-of-type) {
      margin-top: 0;
      padding-top: 0;

      &::after {
        opacity: 1;
      }
    }
  }
}

.whiteBox {
  width: 100%;
  margin: $basicSpace/2 0 $basicSpace;
  padding: $basicSpace 0;

  @media #{$medium} {
    margin: 60px 0;
  }
}

.paragraph {
  font-family: $fontSerif;
  font-size: $fontL;
  font-weight: $fontLight;
  line-height: 30px;

  &:not(:first-of-type) {
    margin-top: $basicSpace;
  }
}

.paragraphStrong {
  margin: 30px 0 10px;
  font-family: $fontMain;
  font-weight: $fontMedium;
}

.link {
  display: block;
  font-family: $fontSerif;
  font-size: $fontS;
}
