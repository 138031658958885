@import '~styles/common';

.card {
  display: block;
  padding: 16px;
  background: $white;
  margin-bottom: 16px;
  text-decoration: none;
  color: inherit;

  @media #{$medium} {
    padding: 24px;
    transition: all $duration $spring;

    &:hover {
      box-shadow: $boxShadowSmall;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.categoryLabel {
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontMedium;
  color: $brandGreen;
}

.authorInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  order: 2;
  margin-bottom: 12px;

  @media #{$medium} {
    order: 1;
    margin-bottom: 16px;
  }
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.authorMetadata {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.authorName {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $black;
  margin-bottom: 4px;
}

.timeAgo {
  font-family: $fontMain;
  font-size: $fontXxs;
  color: $mediumGray;
}

.title {
  font-family: $fontMain;
  font-size: $fontL;
  font-weight: $fontRegular;
  color: $black;
  margin-bottom: 12px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media #{$medium} {
    font-size: $fontXl;
    margin-bottom: 16px;
    line-height: 1.4;
  }
}

.linkText {
  color: #20DACC;
  text-decoration: underline;
  display: block;
  margin-bottom: 12px;
  font-size: $fontXs;
  font-family: $fontSerif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media #{$medium} {
    font-size: $fontS;
    margin-bottom: 16px;
  }
}

.content {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 1.5;
  color: $black;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media #{$medium} {
    line-height: 1.6;
    margin-bottom: 24px;
  }
}

.footer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.voteGroup {
  display: flex;
  align-items: center;
  background: rgba($catskillWhite, 0.8);
  border-radius: 9999px;
  padding: $smallSpacing / 4;
  justify-content: space-between;
}

.voteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $smallSpacing / 8;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all $shortDuration $spring;

  &:active {
    transform: scale(0.95);
  }

  .icon {
    &.up path {
      fill: $brandGreen;
    }

    &.down path {
      fill: $darkRed;
    }
  }
}

.voteCount {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontMedium;
  min-width: $smallSpacing;
  text-align: center;
  padding: 0 ($smallSpacing / 4);

  &.up {
    color: $brandGreen;
  }

  &.down {
    color: $darkRed;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background: rgba($catskillWhite, 0.8);
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  font-family: $fontMain;
  font-size: $fontXs;
  color: $black;
  min-height: 32px;

  .icon {
    margin-right: 6px;
    color: $slategray;
  }
}

.commentButton {
  composes: actionButton;
}

.awardButton {
  composes: actionButton;

  .icon {
    color: #FFB800;
  }
}

.menuContainer {
  position: relative;
  order: 1;
  margin-left: auto;

  @media #{$medium} {
    order: 2;
  }
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color $duration $spring;
  border-radius: 50%;

  &:hover {
    background-color: rgba($black, 0.05);
  }
}

.menuIcon {
  width: 16px;
  height: 16px;
  color: $slategray;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 160px;
  background: $white;
  border: 1px solid $mysticGray;
  border-radius: 4px;
  box-shadow: $boxShadowSmall;
  z-index: 1;
  margin-top: 4px;
}

.menuItem {
  width: 100%;
  padding: 12px 16px;
  font-family: $fontSerif;
  font-size: $fontXs;
  color: $black;
  text-align: left;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color $duration $spring;

  &:hover {
    background-color: rgba($brandGreen, 0.05);
    border: 1px solid $brandGreen;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $mysticGray;
  }
}
