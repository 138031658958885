@import '~styles/common';

$sidebarWidth: 33%;
$spacing-small: 20px;
$spacing: 40px;
$spacing-large: 80px;

.layout {
  flex-grow: 1;
  background-color: $white;
}

.sidebar {
  display: none;

  &.stretched {
    background: {
      position: center;
      size: cover;
    }
  }

  @media #{$medium} {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: $sidebarWidth;
    height: 100%;
    padding-top: $spacing;
    overflow-y: auto;
    text-align: center;

    background: {
      position: 100% 0;
      color: $xGreenBlack;
      size: cover;
      repeat: no-repeat;
    }
  }

  @media #{$large} {
    padding-right: 120px;
    text-align: right;
  }

  @media #{$medium-high} {
    background-size: auto 720px;
  }
}

.wrapper {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  &.withPhotoBackground {
    justify-content: space-between;
    padding-top: 0;
  }

  @media #{$medium-high} {
    height: 680px;
  }
}

.navbarMobile {
  width: 100%;
  min-height: $spacing-large;

  @media #{$medium} {
    display: none;
  }
}

.logo {
  height: 38px;
}

.backButton {
  display: flex;
  margin-bottom: 10px;
  padding-left: 10px;
}

.content {
  margin: 0 auto;
  padding: 0 $spacing-small $spacing;
  background-color: $white;

  &.withOnboarding {
    @media #{$large} {
      padding-right: 120px;
      padding-left: 120px;
    }

    @media #{$xlarge} {
      padding-left: 120px;
    }
  }

  @media #{$small} {
    padding: 0 $spacing-large $spacing-large;
  }

  @media #{$medium} {
    margin-left: $sidebarWidth;
    padding-top: 160px;
  }

  @media #{$xlarge} {
    padding-left: 228px;
  }
}

.title {
  @include titleMain();
}

.description {
  margin: $spacing-small 0 70px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 22px;
}
