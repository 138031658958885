@import '~styles/common';

$mediumSpacing: 40px;
$spacing: 60px;

.header {
  @include container;

  position: relative;
  margin-top: 170px;
  padding-bottom: 115px;

  @media #{$medium} {
    margin-top: 160px;
    padding-bottom: 80px;
  }
}

.headerwithoutEdit {
  composes: header;
  margin-top: 120px;
  padding-bottom: 105px;

  @media #{$small} {
    margin-top: 160px;
  }

  @media #{$medium} {
    padding-bottom: 80px;
  }
}

.statusHidden {
  padding-bottom: 40px;

  @media #{$medium} {
    padding-bottom: 80px;
  }
}

.headerContainer {
  position: relative;
  display: grid;
  grid-gap: $mediumSpacing $smallSpacing;
  grid-template-areas:
    'userPhoto title'
    'details details';
  grid-template-columns: $photoMobileSize 1fr;

  @media #{$xsmall} {
    grid-gap: $mediumSpacing;
    grid-template-areas:
      'userPhoto title'
      'userPhoto details';
    grid-template-columns: $photoMediumSize 1fr;
  }

  @media #{$medium} {
    grid-column-gap: $spacing;
    grid-template-columns: $photoSize 1fr;
  }
}

.photo {
  grid-area: userPhoto;
}

.name {
  @include titleMain;

  margin-top: 6px;
  font-size: $fontL;
  font-weight: $fontMedium;
  line-height: 24px;
  grid-area: title;

  @media #{$xsmall} {
    margin: 0;
    font-weight: $fontBold;
  }

  @media #{$small} {
    margin-right: 140px;
  }

  @media #{$medium} {
    margin-right: 200px;
  }
}

.details {
  grid-area: details;
}

.main {
  padding-bottom: 40px;
  background-color: $lightBlue;

  @media #{$small} {
    padding-bottom: 70px;
  }

  @media #{$medium} {
    padding-bottom: 110px;
  }
}

.editHeader {
  position: absolute;
  top: -45px;
  right: 30px;

  @media #{$medium} {
    top: 0;
  }
}

.contactButton {
  composes: button from '../../../components/Button/Button.module.scss';
  width: 100%;
  min-height: 50px;
  margin-top: 40px;
  padding: 12px 20px;
  font-size: $fontXxs;

  @media #{$small} {
    position: absolute;
    top: 0;
    right: 30px;
    width: auto;
    margin-top: 0;
  }

  @media #{$medium} {
    top: 0;
    min-height: 60px;
    padding: 22px 40px;
  }
}

.container {
  @include container;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 40px;

  @media #{$medium} {
    flex-direction: row;
    margin-top: 60px;
  }
}

.statusContainer {
  @include container;
}

.leftSide {
  flex: 1;
  width: 100%;
}

.socialAddButton {
  margin-top: 35px;
}

.spinnerContainer {
  min-height: 500px;
}

.modalHeader {
  @include titleSecondary;

  margin-bottom: 40px;

  @media #{$medium} {
    font-size: $fontXxl;
  }
}
