@import '~styles/common';

.wrapper {
  display: flex;
  cursor: pointer;
}

.input {
  display: none;
}

.box {
  @include square(24px);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 8px;
  border: 2px solid $inputBorder;
}

.tick {
  @include square(12px);

  opacity: 0;
  transform: scale(0.75);
  transition: transform $duration $spring, opacity $duration $spring, border $duration $spring;
}

.checked {
  background: $brandGreen;
  border-color: $brandGreen;

  .tick {
    opacity: 1;
    transform: scale(1);
  }
}

.invalid {
  border-color: $error;
}

.labelWrapper {
  position: relative;
  margin: 10px 0 16px 10px;
}

.label {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 18px;
}
