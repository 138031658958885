@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~../../../../assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~../../../../assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 80px 40px;
  color: white;

  @media #{$medium} {
    max-width: 45%;
    margin-left: 120px;
    padding: 160px 0 20px;
  }
}

.headingTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.headingSubtitle {
  padding-bottom: 20px;
  font-size: $fontL;

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.headingDescription {
  @include paragraph;

  padding-bottom: 80px;
}

.newEvent {
  margin-top: 40px;

  @media #{$medium} {
    @include container;
    min-height: 100vh;
    margin-top: 60px;
  }
}
