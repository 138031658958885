@import '~styles/common';

.formWrapper {
  width: 100%;
  background: $white;
  padding: 24px 32px;
  margin-bottom: 32px;
  border: 2px solid transparent;
  transition: border-color $duration $spring;

  &:hover {
    border-color: $brandGreen;
  }
}

.textareaBase {
  width: 100%;
  display: block;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 20px;
  color: $inputText;
  background: $dirtyWhite;
  border: 1px solid $inputBorder;
  transition: border $duration $spring, background $duration $spring;
  resize: none;

  &::placeholder {
    color: $textPlaceholder;
  }

  &:focus {
    background: $brandGreenTransparent;
    outline: none;
    border: {
      width: 1px;
      color: $brandGreen;
      right-width: 4px;
      style: solid;
    }
  }
}

.textareaCollapsed {
  composes: textareaBase;
  padding: 14px 20px;
  height: 50px;
  overflow: hidden;
}

.textareaExpanded {
  composes: textareaBase;
  padding: 14px 20px;
  min-height: 160px;
  max-height: 400px;
  resize: vertical;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  gap: 16px;

  @media #{$medium} {
    gap: 24px;
  }
}

.selectWrapper {
  flex: 1;
  max-width: 300px;
}

.submitButton {
  min-width: 120px;
  height: 48px;
  padding: 12px 24px;
  font-family: $fontMain;
  font-size: $fontXs;
  font-weight: $fontMedium;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: $brandGreen;
  border: none;
  transition: background-color $duration $spring;

  &:hover {
    background-color: $darkGreen;
  }

  &:disabled {
    background-color: $lightGray;
    cursor: not-allowed;
  }
}
