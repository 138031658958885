@import '~styles/common';

.dropdown {
  position: absolute;
  top: 100%;
  right: 20px;
  z-index: $navZIndex;
  display: none;
  width: 258px;
  padding: 30px 0;
  color: transparent;
  background-color: $white;
  transform: scale(1, 0);
  box-shadow: $boxShadow;
  transition: transform $shortDuration ease-in-out, color $shortDuration;
  transform-origin: top center;

  &.open {
    color: $black;
    transform: scale(1);
  }

  @media #{$medium} {
    display: block;
  }
}
