@import '~styles/common';

.homeButton {
  min-height: 40px;
  margin-right: 0;
  padding: 10px 30px 10px 0;
  font-size: $fontM;
  font-weight: $fontBlack;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: capitalize;
  border: none;

  @media #{$medium} {
    display: none;
  }
}
