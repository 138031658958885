@import '~styles/common';

.voteGroup {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  padding: $smallSpacing / 4;
  justify-content: space-between;
}

.withBg {
  background: rgba($catskillWhite, 0.8);
}

.voteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $smallSpacing / 8;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all $shortDuration $spring;

  &:active {
    transform: scale(0.95);
  }

  .icon {
    width: 15px;
    height: 15px;

    &.up path {
      fill: $brandGreen;
    }

    &.down path {
      fill: $darkRed;
    }
  }
}

.voteCount {
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontMedium;
  min-width: $smallSpacing;
  text-align: center;
  padding: 0 ($smallSpacing / 4);

  &.up {
    color: $brandGreen;
  }

  &.down {
    color: $darkRed;
  }
}
