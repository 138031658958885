@import '~styles/common';

.container {
  @include container;

  position: relative;

  @media #{$medium} {
    margin: 120px auto 60px;
  }
}

.title {
  @include titleMain;
}

.subtitle {
  @include subtitle;
}

.tagline {
  @include titleSecondary;
}

.row {
  @include row;
}

.strike {
  text-decoration: line-through;
}

.gainContainer {
  @include halfWidthContainer;

  margin-top: 40px;

  @media #{$medium} {
    margin-top: 80px;

    &:nth-of-type(odd) {
      padding-right: 65px;
    }

    &:nth-of-type(even) {
      padding-left: 65px;
    }
  }
}

.featureTitle {
  @include titleSecondary;

  padding-left: 28px;
  font-family: $fontSerif;
  font-size: $fontL;

  &::before {
    width: 8px;
  }

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.featureParagraph {
  @include paragraph;

  margin-top: 30px;

  @media #{$medium} {
    margin-top: 40px;
    font-size: $fontS;
    line-height: 24px;
  }
}

.featureBullet {
  list-style: disc;

  @media #{$medium} {
    margin-top: 5px;
    margin-left: 28px;
    font-size: $fontS;
    line-height: 24px;
  }
}
