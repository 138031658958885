@import '~styles/common';

.icon {
  @include square(24px);

  margin-right: 8px;

  * {
    transition: fill 0.2s ease-in-out;
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: $fontXxs;
  font-weight: $fontMedium;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:disabled {
    color: $jaki;
    cursor: auto;

    .icon * {
      fill: $jaki;
    }
  }

  @media #{$medium} {
    font-size: $fontXs;
  }
}
