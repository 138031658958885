@import '~styles/common';

.card {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: $lightGray;
  }
}

.userPhoto {
  width: 60px;
  height: 60px;
  border-width: 4px;
}

.nameAndMessage {
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 5px 10px;
}

.name {
  font-size: 20px;
}

.message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
