@import '~styles/common';

$resultsAreaWidth: 66%;
$marginLarge: 40px;
$wrapperPadding: 70px;

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.searchBarContainer {
  @include backgroundWithPhoto;

  background-image: url('~assets/images/profiles-background.svg');

  @media #{$medium} {
    background-image: url('~assets/images/profiles-background.svg');
  }
}

.contentWrapper {
  @include container;

  padding-top: $wrapperPadding * 2;
  padding-bottom: $wrapperPadding;
}

.searchResultsWrapper {
  @include container;
}

.searchResultsHeader {
  @include titleMain;

  margin-top: $marginLarge;

  @media #{$medium} {
    margin-top: $marginLarge * 2;
  }

  @media #{$small} {
    margin-bottom: -24px;
  }
}

.searchResultsCount {
  @include subtitle;

  font-weight: $fontRegular;

  @media #{$small} {
    margin-top: 48px;
  }

  @media #{$medium} {
    font-size: $fontL;
  }
}

.emphasized {
  font-weight: $fontBlack;

  &:first-of-type {
    margin-right: 10px;
  }

  &:last-of-type {
    margin-left: 5px;
  }
}

.profilesListWrapper {
  @include container;

  width: 100%;
  padding: 0;
  padding-bottom: $marginLarge;

  @media #{$medium} {
    width: $resultsAreaWidth;
    max-width: $resultsAreaWidth;
    padding-bottom: $marginLarge * 2;
  }
}

.results {
  display: flex;
  margin-top: 40px;

  @media #{$medium} {
    min-height: 100vh;
    margin-top: $marginLarge * 1.2;
  }
}

.spinner {
  margin-top: 54px;
}

.showFiltersButton {
  width: 100%;

  @media #{$medium} {
    display: none;
  }
}

.orderAndPaginationArea {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  place-content: space-between;

  @media #{$small} {
    align-items: center;
    flex-direction: row;
    margin: 24px 0;
  }

  @media #{$medium} {
    margin: 11px 0 15px;
  }
}

.downPagePagination {
  display: flex;
  place-content: flex-end;
}
