@import '~styles/common';

.button {
  composes: discipline from '../common.module.scss';

  &:hover,
  &:focus,
  &:active {
    .icon {
      @include square(12px);
    }
  }
}

.selected {
  color: $cadetGray;

  svg {
    display: inline-block;
  }

  &:hover,
  &:focus,
  &:active {
    font-weight: $fontLight;
    color: $cadetGray;
  }
}

.discipline {
  &:last-child {
    margin-bottom: 30px;
  }
}
