@import '~styles/common';

.boxHeader {
  composes: boxHeader from '../common.module.scss';
}

.boxText {
  composes: boxText from '../common.module.scss';
}

.headerTitle {
  @include titleSecondary;
}

.socialBox {
  width: 100%;

  @media #{$medium} {
    width: 380px;
    margin-top: 0;
    margin-left: 24px;
  }
}

.addButton {
  margin-top: 35px;
}

.formRow {
  position: relative;
  margin-bottom: 40px;
}

.newField {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $xLightGray;
}

.buttons {
  composes: buttons from '../ProfileBox/ProfileBox.module.scss';
  margin-top: 40px;

  @media #{$medium} {
    justify-content: space-between;
  }
}

.submit {
  composes: submit from '../ProfileBox/ProfileBox.module.scss';

  @media #{$medium} {
    min-width: 150px;
  }
}

.cancel {
  composes: cancel from '../ProfileBox/ProfileBox.module.scss';

  @media #{$medium} {
    min-width: 150px;
  }
}
