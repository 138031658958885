@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,500|Roboto:300,400,500,700,900&display=swap');

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  outline: none;
  box-sizing: inherit;
}

body {
  font-family: $fontMain;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $black;
  background-color: $lightBlue;

  &.scroll-lock {
    overflow: hidden;

    @media #{$medium} {
      &:not(.desktop-lock) {
        overflow: unset;
      }
    }
  }
}

a {
  font-weight: $fontMedium;
  color: $darkGreen;
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $dirtyWhite inset !important;
  box-shadow: 0 0 0 30px $dirtyWhite inset;
}
