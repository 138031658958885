@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 80px 40px;
  color: white;

  @media #{$medium} {
    max-width: 45%;
    margin-left: 120px;
    padding: 160px 0 120px;
  }
}

.headingTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.headingSubtitle {
  padding-bottom: 20px;
  font-size: $fontL;

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.headingDescription {
  @include paragraph;

  padding-bottom: 80px;
}

.postOfferButton {
  min-height: 60px;
  padding: 22px 40px;
  font-size: $fontXs;
  font-weight: $fontMedium;
  line-height: 1;
  letter-spacing: 1px;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  background-color: $brandGreen;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $xGreenBlack;
    }
  }
}

.jobs {
  @include container;

  margin-top: 40px;

  @media #{$medium} {
    min-height: 100vh;
    margin-top: 80px;
  }
}

.pageTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.pageSubtitle {
  @include paragraph;

  padding-bottom: 40px;
  font-size: $fontXs;

  @media #{$medium} {
    max-width: 60%;
    padding-bottom: 80px;
    font-size: $fontMedium;
  }
}

.emphasized {
  padding: 0 5px;
  font-weight: $fontMedium;
}
