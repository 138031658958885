@import '~styles/common';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modalZIndex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba($pureBlack, 0.5);

  @media #{$small} {
    padding: 18px;
  }
}

.modal {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: $white;

  @media #{$small} {
    width: auto;
    height: auto;
  }
}

.large {
  @media #{$small} {
    @include container;

    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.headerBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  background-color: $catskillWhite;
}

.header {
  font-size: $fontL;

  @media #{$medium} {
    font-size: 28px;
  }
}

.button {
  @include iconBtnHoverEffect(30px);

  margin-right: -10px;
  padding: 10px;

  @media #{$medium} {
    &::before {
      @include square(50px);
    }
  }
}

.icon {
  @include square(16px);

  @media #{$medium} {
    @include square(24px);
  }
}

.container {
  padding: 20px;

  @media #{$xsmall} {
    padding: 40px;
  }
}
