$white: #fff;
$black: #002a36;
$xBlack: #0c1121;
$pureBlack: #000;

$brandBlue: #558bff;
$lightBlue: #eef2f9;

$brandGreen: #00dacc;
$grassGreen: #35b27d;
$darkGreen: #00857d;
$xGreenBlack: #00242d;
$chathamsGreen: #115567;
$brandGreenTransparent: rgba($brandGreen, 0.05);

$dirtyWhite: #fafcff;
$xLightGray: #d9e2ef;
$jaki: #e6ecf5;

$lightGray: #b9c7dc;
$mediumGray: #909fb7;
$cadetGray: #a5b2c6;
$slategray: #6f7c93;
$mysticGray: #dfe4ea;
$catskillWhite: #f1f4f9;
$mysticDarkGray: #d0d7e0;

$red: #ff4750;
$darkRed: #cc3940;
$redTransparent: rgba($red, 0.05);

$inputBorderColor: $lightGray;
$inputBorder: $lightGray;
$inputText: $black;
$labelText: $black;
$textPlaceholder: $mediumGray;
$error: $red;
$errorBackground: $redTransparent;
$success: $grassGreen;

$boxShadow: 0 20px 100px 0 rgba($xBlack, 0.02);
$boxShadowPhoto: 0 20px 100px 0 rgba($xBlack, 0.24);
$boxShadowSmall: 0 20px 70px 0 rgba($xBlack, 0.1);
$boxShadowTop: 0 -20px 100px 0 rgba($xBlack, 0.2);
