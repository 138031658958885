@import '~styles/common';

.overlay {
  padding: 20px;
}

.modal {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  background-color: $black;
}

.text {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  color: $white;
}

.agree {
  color: $grassGreen;
  cursor: pointer;
}

.container {
  max-width: 250px;
  padding: 20px;
}
