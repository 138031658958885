@import '~styles/common';

$spacingXsmall: 10px;
$spacingSmall: 15px;
$spacing: 20px;
$spacingLarge: 25px;
$spacingXlarge: 30px;

.title {
  font-size: $fontXxs;
  font-weight: $fontMedium;
  line-height: 23px;
  cursor: pointer;

  @media #{$xxsmall} {
    font-size: $fontXs;
  }

  @media #{$medium} {
    padding-right: $spacing;
    font-size: $fontS;
    border-right: 1px solid $xLightGray;
  }

  @media #{$large} {
    padding-right: $spacingLarge;
  }
}

.textarea {
  flex-grow: 1;
  width: 100%;
  max-height: 60px;
  margin: $spacingXsmall (-$spacingXsmall) 0 (-$spacingSmall);
  padding: $spacingXsmall $spacingSmall;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 26px;
  color: $black;
  border: none;
  border-top: 1px solid $xLightGray;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: $mediumGray;
  }

  &:disabled {
    color: $mediumGray;
  }

  @media #{$xxsmall} {
    margin: $spacingSmall (-$spacing) 0;
    padding: $spacingXsmall $spacing;
  }

  @media #{$medium} {
    width: auto;
    margin: 0;
    padding: 16px $spacing 0;
    font-size: $fontS;
    border-top: none;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 58px;
  margin: -73px 0 $spacingXlarge;
  padding: $spacing 0 $spacingXsmall;
  background-color: $white;
  border-bottom: 2px solid $brandGreen;
  box-shadow: 0 -1px 0 0 $jaki, 0 0 40px 0 rgba($black, 0.05);
  box-sizing: content-box;

  &::before {
    @include square(16px);

    position: absolute;
    top: -8px;
    left: 75px;
    display: none;
    background-color: $white;
    content: '';
    transform: rotate(-45deg);
    box-shadow: 1px -1px 0 0 $jaki;

    @media #{$small} {
      display: block;
    }

    @media #{$medium} {
      left: 110px;
    }
  }

  &.isStatusHidden {
    border-bottom-color: $lightGray;

    .title,
    .input {
      opacity: 0.4;
    }
  }

  &:not(.isEditable) {
    .title {
      flex-shrink: 0;
    }
  }

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
    width: auto;
    margin-top: -35px;
    padding: $spacingXsmall $spacing $spacingXsmall $spacingXlarge;
  }

  @media #{$large} {
    margin-top: -40px;
    padding: $spacingXsmall 40px;
  }
}

.mobileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 5px $spacing;

  &.isEditable {
    padding: 0 $spacingXsmall 0 $spacingSmall;

    @media #{$xxsmall} {
      padding: 0 $spacing;
    }

    @media #{$medium} {
      padding: 0;
    }
  }

  @media #{$medium} {
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 0;
  }
}

.text {
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 26px;

  @media #{$medium} {
    padding-left: $spacingLarge;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  min-height: auto;
  margin-bottom: $spacing;

  @media #{$small} {
    margin-bottom: 0;
  }
}

.cancel {
  min-height: auto;
  margin-right: $spacing;
  padding: 16px 28px;
}
