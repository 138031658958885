@import '~styles/common';

$filtersAreaWidth: 34%;
$defaultPadding: 20px;
$mobileFiltersSize: calc(100% - 2 * 12px);

.wrapperBackground {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  display: none;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: rgba($pureBlack, 0.5);

  @media #{$medium} {
    position: relative;
    z-index: 0;
    display: block;
    width: $filtersAreaWidth;
    max-width: $filtersAreaWidth;
    padding: 0;
    background-color: transparent;
  }
}

.wrapper {
  position: fixed;
  top: 50%;
  width: $mobileFiltersSize;
  max-height: $mobileFiltersSize;
  padding: $defaultPadding;
  overflow: auto;
  background-color: $white;
  transform: translateY(-50%);

  @media #{$medium} {
    position: relative;
    top: 0;
    max-height: none;
    margin: 0 24px 0 0;
    padding: 0;
    overflow: initial;
    background-color: transparent;
    transform: none;
  }
}

.isVisible {
  display: block;
}

.heading {
  display: none;
  align-items: center;
  height: 34px;
  margin-bottom: 18px;
  place-content: space-between;

  @media #{$medium} {
    display: flex;
    margin: 14px 0 18px;
  }
}

.title {
  font-size: $fontXl;
}

.filtersArea {
  display: flex;
  flex-direction: column;
  background-color: $white;

  @media #{$medium} {
    padding: 30px $defaultPadding;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}

.academicTitleLabel {
  display: flex;
  margin-top: 0;
  place-content: space-between;
}

.statusFilter {
  margin-bottom: 28px;
  padding: 12px 0;
  border-bottom: 1px solid $lightGray;
}

.statusFilterLabel {
  font-size: 16px;
  line-height: 20px;
}

.statusFilterCheckbox {
  padding-top: 16px;
}

.submitButton {
  width: 100%;
  margin-top: 40px;
}

.educationsAutocomplete {
  margin-top: 8px;
}

.grantsHeadingContainer {
  position: relative;
}

.grantsHeading {
  padding: 20px 0;
  font-size: $fontS;
  text-transform: uppercase;
}

.grantsWrapper {
  padding-left: 18px;
  border-left: 1px solid $lightGray;
}

.grantNameSelect {
  margin-bottom: 0;
}

.mobileHeading {
  display: flex;
  align-items: center;
  margin: -20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: $catskillWhite;
  place-content: space-between;

  @media #{$medium} {
    display: none;
  }
}

.icon {
  @include square(24px);
}
