@import '~styles/common';

.box {
  margin: 20px 0;
  padding: 20px 20px 30px;
  background: $white;
  border: 2px solid $white;

  &:not(.landing) {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media #{$medium} {
    margin: 30px 0;
    padding: 30px 30px 40px;
  }
}

.focus {
  border: 2px solid $brandGreen;
  box-shadow: 0 8px 0 0 $brandGreen;
}

.landing {
  display: inline-flex;
  width: 100%;
  margin: 0;
  padding: 30px 20px;
  border: 0;
  box-shadow: $boxShadow;

  @media #{$xxsmall} {
    padding: 30px 40px;
  }

  @media #{$xsmall} {
    width: auto;
    padding: 30px 20px;
  }

  @media #{$small} {
    padding: 40px;
  }

  @media #{$medium} {
    padding: 40px 60px;
  }
}
