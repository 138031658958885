@import '~styles/common';

.description {
  composes: authLayoutContent from '../common.module.scss';
}

.form {
  composes: authLayoutContent from '../common.module.scss';
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.cancelButton {
  width: 100%;

  @media #{$small} {
    width: 180px;
  }
}

.submit {
  width: 100%;

  @media #{$small} {
    flex-grow: 1;
    width: auto;
  }
}
