@import '~styles/common';

$button-padding-x: 18px;
$icon-size: 16px;

.iconBtn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px $button-padding-x;
  cursor: pointer;
}

.icon {
  @include square($icon-size);
}

.input {
  padding-right: $icon-size + 2 * $button-padding-x;
}
