@import '~styles/common';

.title {
  composes: authLayoutContent from '../common.module.scss';
}

.description {
  font-size: $fontS;
  line-height: 32px;
}

.email {
  display: block;
  font-weight: $fontMedium;
}
