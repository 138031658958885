@import '~styles/common';

$spacingSmall: 20px;
$spacing: 40px;
$spacingLarge: 60px;

.container {
  display: grid;
  grid-gap: $spacing;
  grid-template-areas:
    'name'
    'photo'
    'affiliations';
  grid-template-columns: 1fr;

  @media #{$medium} {
    padding: $spacing $spacingLarge;
    grid-gap: $spacingLarge;
    grid-template-areas:
      'photo name'
      'photo affiliations';
    grid-template-columns: $avatarFormWidth 1fr;
  }
}

.photo {
  grid-area: photo;
  justify-self: center;

  @media #{$medium} {
    justify-self: start;
  }
}

.instructions {
  margin-top: $spacing;
  line-height: 22px;
  text-align: center;
}

.instructionsTitle {
  display: block;
  font-size: $fontS;
  font-weight: $fontBold;
}

.instructionsText {
  display: block;
  font-size: $fontXs;
}

.name {
  @include titleMainCenter;

  margin: 0;
  font-size: $fontL;
  font-weight: $fontMedium;
  grid-area: name;
  justify-self: center;

  @media #{$xsmall} {
    font-size: 32px;
    font-weight: $fontBold;
  }

  @media #{$medium} {
    @include titleMain;

    font-size: $fontXxl;
    justify-self: flex-start;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 40px;

  @media #{$xsmall} {
    flex-wrap: nowrap;
  }
}

.button {
  width: 100%;
  margin: 0 0 $spacingSmall;

  @media #{$xsmall} {
    width: 180px;
  }
}

.submitButton {
  composes: button;

  @media #{$xsmall} {
    margin: 0 0 $spacingSmall $spacingSmall;
  }

  @media #{$small} {
    margin: 0;
  }
}
