@import '~styles/common';

.slide {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: $dirtyWhite;

  @media #{$small} {
    flex-wrap: nowrap;
    height: 560px;
  }

  @media #{$medium} {
    height: 680px;
  }
}

.boxWrapper {
  display: flex;
  align-items: center;

  @media #{$small} {
    @include container;
  }
}

.box {
  z-index: 1;
  flex-basis: 100%;
  margin-right: -120px;
  padding: 20px;
  box-shadow: $boxShadowSmall;

  @media #{$small} {
    flex-basis: 62%;
    padding: 30px;
  }

  @media #{$medium} {
    padding: 60px;
  }
}

.figure {
  position: relative;
  flex-basis: 100%;
  height: 256px;

  @media #{$xsmall} {
    height: 380px;
  }

  @media #{$small} {
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    height: 100%;
    max-width: 640px;
  }

  @media #{$medium} {
    left: 50%;
    width: 50%;
    margin-left: 40px;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media #{$small} {
    object-position: left;
  }

  @media #{$medium} {
    object-position: center;
  }
}

.copyrights {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 20px;
  font-size: $fontXxs;
  line-height: 20px;
  color: $white;
  background-color: $black;
}

.title {
  margin-bottom: 15px;
  font-size: 26px;
  font-weight: $fontBlack;
  line-height: 30px;

  @media #{$medium} {
    font-size: 36px;
    line-height: 60px;
  }
}

.text {
  @include paragraph;

  display: -webkit-box;
  margin-bottom: 30px;
  overflow: hidden;
  font-size: $fontM;
  -webkit-box-orient: vertical;

  @media #{$xsmall} {
    font-size: $fontXs;
  }

  @media #{$small} {
    margin-bottom: 20px;
    font-size: $fontS;
  }
}

.date {
  $fontXxxs: 10px;

  font-weight: $fontMedium;
  color: $darkGreen;
  text-transform: uppercase;

  @media #{$small} {
    $fontXxs: 12px;
  }
}
