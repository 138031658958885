@import '~styles/common';

.boxText {
  composes: boxText from '../common.module.scss';
}

.container {
  composes: containerWithBorder from '../common.module.scss';
}

.institution {
  composes: greenTitle from '../common.module.scss';
}

.title {
  margin: 10px 0 6px;
}

.formRow {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media #{$small} {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.provider {
  @media #{$small} {
    flex-basis: 40%;
    margin-right: 24px;
  }
}

.name {
  @media #{$small} {
    flex-grow: 1;
  }
}

.form .submit {
  margin-top: 0;
}
