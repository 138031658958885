@import '~styles/common';

.headerTitle {
  @include titleSecondary;
}

.boxHeader {
  composes: boxHeader from '../common.module.scss';
}

.boxText {
  composes: boxText from '../common.module.scss';
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 60px;

  @media #{$xsmall} {
    flex-wrap: nowrap;
  }
}

.button {
  min-width: 100%;

  @media #{$xsmall} {
    min-width: 180px;
  }
}

.submit {
  composes: button;
  margin-bottom: 20px;

  @media #{$small} {
    margin-bottom: 0;
  }
}

.cancel {
  composes: button;

  @media #{$xsmall} {
    margin-right: 20px;
  }
}
