@import '~styles/common';

$listDesktopHeight: 460px;

.wrapper {
  composes: box list from '../common.module.scss';
  height: auto;
  padding: 30px 0;
  background: none;

  @media #{$medium} {
    height: $listDesktopHeight;
    padding: 40px;
    border-left: none;
  }
}

.title {
  @include titleSecondary();

  margin-bottom: 20px;
  font-size: $fontS;
}

.hint {
  padding-bottom: 20px;
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontLight;
  line-height: 16px;
  border-bottom: 1px solid $xLightGray;
}
