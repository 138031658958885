@import '~styles/common';

.section {
  max-width: 100%;
  overflow: hidden;

  @media #{$medium} {
    margin: 80px auto 60px;
  }
}

.container {
  @include container;
}

.title {
  @include titleMain;
}

.subtitle {
  @include subtitle;

  margin-bottom: 20px;
}

.slider {
  width: 100vw;
  max-width: 1440px;
}
