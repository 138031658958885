@import '~styles/common';

.eventCard {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, border 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }

  &.highlighted {
    border: 4px solid $brandGreen;
    box-shadow: 0 6px 12px rgba($brandGreen, 0.2);

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 8px 16px rgba($brandGreen, 0.3);
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  background: linear-gradient(45deg, #ff416c, #ff4b2b);
}

.eventImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.actionButtons {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.actionButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: white;
    transform: scale(1.1);
  }

  .icon {
    width: 20px;
    height: 20px;
    color: $brandGreen;
  }
}

.eventContent {
  padding: 1.5rem;
}

.eventTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.eventDetails {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  margin-bottom: 0.5rem;
  font-family: $fontSerif;
}

.detailRow {
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  max-width: 100%;
  gap: 0.5rem;
}

.label {
  color: #002A36;
  min-width: 85px;
  flex-shrink: 0;
  font-weight: 400;
}

.value {
  color: #8595AE;
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.link {
  color: #20dacc;
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.linkContainer {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  overflow: hidden;
}

.organizer {
  font-family: $fontSerif;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1rem;
}

.organizerImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.organizerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.organizerName {
  font-size: 0.875rem;
  font-weight: 400;
  color: #002A36
}

.eventDescription {
  font-size: 0.875rem;
  font-family: $fontSerif;
  font-weight: 300;
  line-height: 18px;
  color: #002A36;
  margin-top: 1rem;
  overflow: visible;
  text-overflow: initial;
  display: block;
}
