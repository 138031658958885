@import '~styles/common';

$imageSize: 98px;
$defaultLineHeight: 19px;
$marginSmall: 12px;
$defaultPadding: 20px;
$paddingXs: 5px;

.wrapper {
  display: block;
  margin-bottom: 30px;
  padding: $defaultPadding;
  color: $black;
  background-color: $white;
  border: 2px solid transparent;
  border-bottom: 8px solid transparent;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: $brandGreen;
  }

  @media #{$medium} {
    padding: $defaultPadding * 2 $defaultPadding * 2 32px;
  }
}

.basicUserInfoArea {
  display: grid;
  grid-template-areas:
    'avatar title'
    'details details';
  grid-template-columns: $imageSize + $defaultPadding 1fr;

  @media #{$xsmall} {
    grid-template-areas:
      'avatar title'
      'avatar details';
  }
}

.avatar {
  @include square($imageSize);

  margin-right: 30px;
  border-width: 4px;
  grid-area: avatar;
}

.details {
  margin-top: $marginSmall;
  grid-area: details;
}

.name {
  margin-top: $marginSmall;
  font-size: $fontL;

  @media #{$medium} {
    margin-top: 0;
    font-size: $fontXl;
    line-height: 32px;
  }
}

.heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.profileLink {
  @include subtitle;

  margin-top: $marginSmall;
  font-size: $fontXxs;
  letter-spacing: 1px;
  color: $darkGreen;

  @media #{$medium} {
    margin-top: 0;
    margin-left: 18px;
    font-size: $fontXs;
  }
}

.affiliation {
  @include paragraph;

  @media #{$medium} {
    font-size: $fontS;
    line-height: 1;
  }
}

.affiliationName {
  padding-right: $paddingXs;
  font-weight: $fontMedium;
  text-transform: capitalize;
}

.location {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
  font-size: $fontXs;
  font-weight: $fontRegular;
  line-height: $defaultLineHeight;
  letter-spacing: 1px;
}

.residenceLabel {
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding-right: $paddingXs;
  font-weight: $fontBold;
  color: $darkGreen;
}

.icon {
  width: $marginSmall;
  height: 16px;
  margin-right: 8px;
}

.residenceLocation {
  margin-top: 4px;
  font-weight: $fontMedium;
  letter-spacing: 0;
}

.status {
  @include paragraph;

  margin-top: $marginSmall;
  font-family: $fontSerif;
  font-size: $fontXxs;
  line-height: 14px;
  color: $slategray;

  @media #{$small} {
    margin-top: $defaultPadding;
    font-size: $fontXs;
    line-height: $defaultLineHeight;
  }
}
