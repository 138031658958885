$landingCentralSpace: 40px;

$actionsZIndex: 2;
$navZIndex: 3;
$autocompleteZIndex: 3;
$modalZIndex: 4;
$toastZIndex: 5;

@mixin container {
  margin: 0 auto;
  padding: 0 20px;

  @media #{$small} {
    width: map-get($breakpoints, 'small') - 20px;
  }

  @media #{$medium} {
    width: map-get($breakpoints, 'medium') - 20px;
  }

  @media #{$large} {
    width: map-get($breakpoints, 'large') - 30px;
  }

  @media #{$xlarge} {
    width: map-get($breakpoints, 'xlarge') - 240px;
  }
}

@mixin halfWidthContainer {
  @media #{$small} {
    width: 50%;
    max-width: 50%;

    &:nth-of-type(odd) {
      padding-right: $landingCentralSpace;
    }

    &:nth-of-type(even) {
      padding-left: $landingCentralSpace;
    }
  }
}

@mixin row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@mixin backgroundWithPhoto {
  background: {
    position: right;
    size: cover;
    image: url('~assets/images/basic-background-mobile.svg');
    repeat: no-repeat;
  }

  @media #{$medium} {
    background-image: url('~assets/images/basic-background.svg');
  }
}
