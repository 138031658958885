@import '~styles/common';

.section {
  position: relative;

  @media #{$small} {
    margin: 80px 0 0;
  }

  @media #{$medium} {
    margin: 100px 0 0;
  }

  @media #{$large} {
    margin: 140px 0 0;
  }
}

.container {
  @include container;
  @include row;

  position: relative;
  flex-direction: column;

  @media #{$small} {
    flex-direction: row;
  }
}

.title {
  @include titleMain;
}

.subtitle {
  @include subtitle;
}

.halfWidthContainer {
  @include halfWidthContainer;

  @media #{$medium} {
    padding-bottom: 120px;
  }
}

.featureText {
  max-width: 384px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 30px;
}

.featureContainer {
  position: relative;
  margin: 40px 0;
}

.colorBg {
  position: absolute;
  top: 240px;
  left: 0;
  width: 100%;
  height: 230px;
  background-color: $jaki;

  @media #{$small} {
    top: 0;
    width: calc(40% - 100px);
    height: 100%;
  }
}

.photo2 {
  max-width: 100%;
  max-height: 360px;
  box-shadow: $boxShadowPhoto;

  @media #{$small} {
    max-width: 320px;
    max-height: inherit;
  }

  @media #{$medium} {
    max-width: 420px;
  }

  @media #{$large} {
    max-width: 486px;
  }
}

.photoContainer {
  @include halfWidthContainer;

  position: relative;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media #{$small} {
    margin-top: 0;
  }
}

.mobile {
  @media #{$small} {
    display: none;
  }
}

.desktop {
  display: none;

  @media #{$small} {
    display: block;
  }
}

.contactUsText {
  composes: featureText;
  margin-bottom: 14px;
  font-weight: $fontRegular;
}
