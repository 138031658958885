@import '~styles/common';

$shortestDuration: 0.1s;
$rotation: 135deg;
$navSpacing: 10px;

$backgroundTransition: background-color $shortestDuration $spring;
$rotateTransition: transform $duration $spring;
$topTransition: top $shortestDuration;
$bottomTransition: bottom $shortestDuration;

@mixin bar {
  width: 28px;
  height: 4px;
  background: $brandGreen;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $navZIndex;
  width: 100%;
  opacity: 1;
  transition: transform $duration ease-out, top $duration ease-out;

  &.hidden {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform $duration ease-out, top $duration ease-out;
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    transition: opacity $shortestDuration ease-out;
  }

  &.floating:not(.sticky) {
    position: absolute;

    @media #{$medium} {
      top: 30px;
    }

    @media #{$large} {
      top: 60px;
    }

    @media #{$xlarge} {
      top: 80px;
    }
  }
}

.navbar {
  position: relative;
  z-index: $navZIndex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 28px;
  background-color: $white;
  border-left: 8px solid $brandGreen;
  box-shadow: $boxShadow;
  transition: width $duration ease-out;

  &.sticky,
  &:not(.floating) {
    @media #{$medium} {
      width: 100%;
      transition: none;
    }
  }

  @media #{$medium} {
    @include container;

    height: 80px;
    padding: 14px 20px 14px 38px;
    overflow: visible;
  }
}

.logoMobile {
  height: 28px;

  @media #{$medium} {
    display: none;
  }
}

.logoDesktop {
  display: none;
  height: 38px;
  fill: $black;

  @media #{$medium} {
    display: block;
  }
}

.hamburger {
  z-index: $navZIndex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin: -4px;
  padding: 6px;
  cursor: pointer;
  background-color: $white;

  @media #{$medium} {
    display: none;
  }
}

.bar {
  @include bar;

  position: relative;
  display: block;
  transition: background-color $shortestDuration $duration $spring;

  &::before,
  &::after {
    @include bar;

    position: absolute;
    left: 0;
    content: '';
    transition: $backgroundTransition, $rotateTransition;
  }

  &::before {
    top: 10px;
    transition: $backgroundTransition, $topTransition $duration, $rotateTransition;
  }

  &::after {
    bottom: 10px;
    transition: $backgroundTransition, $bottomTransition $duration, $rotateTransition;
  }

  .open & {
    background: $white;
    transition: none;

    &::before {
      top: 0;
      background: $error;
      transform: rotate(-$rotation);
      transition: $backgroundTransition, $rotateTransition $shortestDuration, $topTransition;
    }

    &::after {
      bottom: 0;
      background: $error;
      transform: rotate($rotation);
      transition: $backgroundTransition, $rotateTransition $shortestDuration, $bottomTransition;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $pureBlack;
  opacity: 0.6;
  animation: fadeIn $duration $spring;

  @media #{$medium} {
    display: none;
    pointer-events: none;
  }
}

.overlayTransparent {
  composes: overlay;
  opacity: 0;
  animation: none;

  @media #{$medium} {
    display: block;
    pointer-events: all;
  }
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100vh;
  min-width: 255px;
  padding: 80px 40px 40px;
  overflow: auto;
  background-color: $white;
  transform: translateX(100%);
  transition: transform 0.4s $spring;

  &.menuOpen {
    transform: translateX(0);
  }

  @media #{$medium} {
    position: static;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0;
    transform: translateX(0);
    transition: none;
  }
}

.navList {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media #{$medium} {
    justify-content: flex-end;
    flex-direction: row;
  }
}

.navListMobile {
  composes: navList;

  @media #{$medium} {
    display: none;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $mysticGray;

  @media #{$medium} {
    width: 1px;
    height: 40px;
    margin: 0 0 0 24px;
  }
}

.login {
  position: relative;
  display: block;
  margin: $navSpacing 0;
  padding: $navSpacing;
  font-size: $fontXs;
  line-height: 22px;
  color: $black;
  text-transform: uppercase;

  @media #{$medium} {
    width: 120px;
    text-align: center;
  }
}

.register {
  width: 100%;
}
