@import '~styles/common';

$pagination-page-size: 40px;

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 14px;

  @media #{$small} {
    width: 50%;
    margin-bottom: 0;
  }

  @media #{$medium} {
    width: fit-content;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $pagination-page-size;
  margin: 0 6px;
  text-align: center;
  border-right: 0;

  @media #{$medium} {
    width: 30px;
  }
}

.pagePrev {
  composes: page;
  margin-left: 0;
  background-color: $white;
}

.pageNext {
  composes: page;
  margin-right: 0;
  background-color: $white;
}

.pageActive {
  background: $black;
}

.pageBrake {
  composes: page;
}

.arrow {
  width: 100%;
  height: 6px;
}

.link {
  display: inline-block;
  width: 100%;
  font-size: $fontXs;
  color: $black;
  cursor: pointer;
}

.linkPrev {
  composes: link;
  transform: rotate(90deg) translateY(-10%);
}

.linkNext {
  composes: link;
  transform: rotate(-90deg) translateY(-10%);
}

.linkBrake {
  composes: link;
}

.linkActive {
  font-weight: $fontBold;
  color: $white;
}
