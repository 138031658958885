@import '~styles/common';

.emptyStateImage {
  height: 168px;
  min-height: 168px;
  margin-top: 48px;
  background: {
    position: top center;
    size: contain;
    image: url('~assets/images/users-empty-state.svg');
    repeat: no-repeat;
  }
}

.emptyStateCopy {
  @include paragraph;

  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;

  @media #{$medium} {
    margin-top: 60px;
  }
}

.emptyStateCopyBold {
  margin-right: 5px;
  font-weight: $fontMedium;
}
