@import '~styles/common';

.outerWrapper {
  position: relative;
}

.wrapper {
  margin-bottom: 0;
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  left: 22px;
  width: 14px;
  height: 16px;
  color: $darkGreen;
  transform: translateY(-50%);
}

.iconError {
  composes: icon;
  color: $red;
}

.input {
  padding: 14px 20px 14px 52px;

  &:focus {
    & + .iconError {
      color: $darkGreen;
    }
  }
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $autocompleteZIndex;
  width: 100%;
  padding: 0;
  background: $white;
  border-color: $xLightGray;
  box-shadow: 0 8px 12px 0 rgba($xBlack, 0.12);
}

.mapsLogo {
  height: 40px;
  margin-right: 20px;
  background: url('~assets/images/powered_by_google_on_white_hdpi.png') right center no-repeat white;
  background-size: 120px 15px;
}

.item {
  padding: 12px 20px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 26px;
  cursor: pointer;
}

.itemActive {
  composes: item;
  color: $black;
  background-color: $brandGreenTransparent;
}

.loading {
  composes: item;
  cursor: auto;
}
