@import '~styles/common';

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 12;
}

.messagesContainer {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 12;
  height: 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.messagesContainer::-webkit-scrollbar {
  display: none;
}

.boxHeader {
  composes: boxHeader from '../../../components/common.module.scss';
}

.headerTitle {
  @include titleSecondary;
}

$navSpacing: 10px;

.navItem {
  width: 100%;
  margin: 0 auto;
}

.link {
  position: relative;
  display: block;
  padding: $navSpacing;
  font-size: $fontXs;
  font-weight: $fontRegular;
  line-height: 22px;
  color: $black;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: $brandGreen;
    content: '';
    transform: translateX(-50%);
    transition: width $duration $spring;
  }

  &:hover,
  &:focus {
    &::after {
      width: 30px;
    }
  }
}
