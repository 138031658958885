@import '~styles/common';

.section {
  padding: 60px 0 40px;
  background: $white;
}

.container {
  @include container;
}

.title {
  margin: 0 auto 20px;
  font-size: $fontXs;
  font-weight: $fontMedium;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

.imagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;

  @media #{$small} {
    max-width: 90%;
    margin: 30px auto;
  }

  @media #{$medium} {
    max-width: 100%;
  }

  @media #{$large} {
    justify-content: space-between;
  }
}

.partners {
  height: 45px;

  @media #{$medium} {
    height: 55px;
    max-width: 160px;
  }

  @media #{$xlarge} {
    height: 60px;
  }
}

.link {
  margin: 20px 10px;

  @media #{$large} {
    margin: 20px;
  }
}
