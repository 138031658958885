@import '~styles/common';

.selectedItem {
  display: inline-block;
  width: auto;
}

.selectedItemTag {
  margin-bottom: 12px;
  padding: 10px 14px 10px 20px;

  &:first-of-type {
    margin-top: 0;
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.button {
  @include iconBtnHoverEffect(24px);

  margin-left: 14px;
}

.icon {
  @include square(10px);

  position: relative;
}
