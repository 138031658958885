@import '~styles/common';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.spinner {
  @include spinner(18px, $black);

  &.isLarge {
    @include spinner(48px, $brandGreen);
  }
}
