@import '~styles/common';

.searchField {
  font-size: $fontXxs;
  border-color: $white;

  &:active,
  &:focus {
    background: $dirtyWhite;
    border-color: $white;
  }

  @media #{$medium} {
    font-size: $fontXs;
  }
}

.searchWrapper {
  label {
    @include subtitle;

    color: $brandGreen;
  }
}

.searchSuffix {
  @include paragraph;

  font-size: $fontXs;
  color: $dirtyWhite;

  @media #{$medium} {
    font-size: $fontS;
  }
}

.form {
  display: flex;
  width: 100%;

  @media #{$medium} {
    width: 55%;
  }
}

.searchIcon {
  margin: 0;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 60px;
  margin-top: 28px;
  padding: 18px 22px;
  background-color: $brandGreen;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      background-color: $xGreenBlack;
    }
  }

  @media #{$medium} {
    margin-top: 48px;
  }
}
