@import '~styles/common';

$spacing: 40px;

.description {
  margin-bottom: $spacing;

  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.boxContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.submitWrapper {
  text-align: right;
}

.submit {
  margin-top: 10px;
  padding: 20px;

  @media #{$medium} {
    margin-top: $spacing;
  }
}

.spinner {
  composes: box from './components/common.module.scss';
  height: auto;
}

.error {
  margin: -10px 0 10px;
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontLight;
  color: $error;

  @media #{$medium} {
    margin: 10px 0 -24px;
  }
}
