@import '~styles/common';

.select-container {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--degree {
    padding-left: 40px;
    border-left: 1px solid $brandGreen;
  }

  .react-select {
    @include reactSelect;

    &__menu {
      position: absolute;

      &__hint {
        padding: 15px 45px;
        font-size: $fontXxs;
        font-style: italic;
        line-height: 26px;
        color: $cadetGray;
        text-align: center;
        border-top: 1px solid $jaki;
      }
    }
  }

  &__inner {
    position: relative;
  }

  &__clear-button {
    position: absolute;
    top: 2px;
    right: 0;
  }

  &.valid {
    .react-select__control {
      @include inputValid;
    }
  }

  &.invalid {
    .react-select__control {
      @include inputInvalid;
    }
  }

  &.isIndicatorVisible {
    .react-select {
      &__indicators {
        display: flex;
      }

      &__indicator-separator,
      &__clear-indicator {
        display: none;
      }
    }
  }

  &__label {
    @include label;

    &--info {
      display: block;
      font-family: $fontSerif;
      font-size: $fontXs;
      font-weight: $fontLight;
      line-height: 26px;
    }
  }

  &__error {
    &--indented {
      left: 40px;
    }
  }
}

.autocomplete-container {
  position: relative;
  margin-bottom: 26px;

  &--half-margined {
    margin-bottom: 14px;
  }
}
