@import '~styles/common';

$navSpacing: 10px;

.navItem {
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: $navSpacing;
  }

  &.dropdown {
    @media #{$medium} {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 $navSpacing;
      }
    }
  }

  @media #{$medium} {
    width: auto;

    &:not(:last-of-type) {
      margin: 0 $navSpacing 0 0;
    }
  }
}

.link {
  position: relative;
  display: block;
  padding: $navSpacing;
  font-size: $fontXs;
  font-weight: $fontRegular;
  line-height: 22px;
  color: $black;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: $brandGreen;
    content: '';
    transform: translateX(-50%);
    transition: width $duration $spring;
  }

  &:hover,
  &:focus,
  &:active {
    &::after {
      width: 20px;
    }
  }

  &.dropdown {
    text-align: center;

    @media #{$medium} {
      &::after {
        top: auto;
        bottom: 90%;
      }

      &:hover,
      &:focus,
      &:active {
        &::after {
          width: 20px;
        }
      }

      &.active {
        &::after {
          width: 20px;
        }
      }
    }
  }

  @media #{$medium} {
    &:hover,
    &:focus,
    &:active {
      &::after {
        width: 30px;
      }
    }
  }
}

.active {
  &::after {
    width: 20px;
  }

  @media #{$medium} {
    &::after {
      width: 30px;
    }
  }
}

.logoutButton {
  composes: link;
  width: 100%;
  cursor: pointer;

  @media #{$medium} {
    composes: link dropdown;
  }
}
