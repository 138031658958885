@import '~styles/common';

.form {
  display: flex;
  flex-wrap: wrap;
}

.inputWrapper {
  position: relative;
  flex-basis: 100%;
  order: 0;

  @media #{$small} {
    flex-basis: 70%;
  }
}

.label {
  position: absolute;
  top: 50%;
  left: 30px;
  z-index: 1;
  color: $black;
  transform: translateY(-47%);

  @media #{$medium} {
    font-size: $fontM;
    transform: translateY(-40%);
  }
}

.input {
  @include input;

  width: 100%;
  height: 60px;
  padding-left: 90px;
  border-color: transparent;

  &:focus,
  &:active {
    background-color: $white;
    border-right-width: 1px;
    outline: none;
  }

  @media #{$medium} {
    font-size: $fontM;
  }
}

.invalid {
  border-color: $error;
  border-right-width: 1px;
}

.button {
  flex-basis: 100%;
  order: 1;
  height: 60px;

  @media #{$small} {
    flex-basis: 30%;
  }
}

.checkboxField {
  order: 2;
  margin-top: 20px;
}
