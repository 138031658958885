@import '~styles/common';

.pageWrapper {
  @include container;

  height: 80vh;
  min-height: 500px;
  margin-top: 72px;

  background: {
    position: center bottom;
    size: 90%;
    image: url('~assets/images/error-page-background.svg');
    repeat: no-repeat;
  }

  @media #{$medium} {
    margin-top: 148px;
    margin-bottom: -60px;
    background-position: center center;
    background-size: contain;
  }

  @media #{$medium} {
    margin-bottom: -100px;
  }

  @media (max-height: 415px) {
    background-position: center 30px;
    background-size: 50%;
  }
}

.title {
  @include titleMain;

  @media #{$medium} {
    margin-bottom: 24px;
  }
}

.emphasized {
  color: $red;
}

.email {
  color: $darkGreen;
}
