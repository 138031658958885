@import '~styles/common';

.container {
  :global(.react-datepicker-popper) {
    z-index: 10;
  }

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker__input-container) {
    input {
      height: 50px;
      margin-bottom: 20px;
      font-family: $fontSerif;
      font-size: 14px;
      font-weight: 300;
      background-color: $dirtyWhite;
      width: 100%;
      padding: 10px;
      border: 1px solid rgb(185, 199, 220);
      border-radius: 2px;
      color: #6B7280;
      transition: all 0.2s ease-in-out;

      &:not(:placeholder-shown) {
        border: 1px solid #20dacc;
        background: #f2fdfc;
      }

      &:focus {
        background: #f2fdfc;
        border: 1px solid #20dacc;
        outline: none;
      }

      &::placeholder {
        font-family: $fontSerif;
        font-size: 14px;
        font-weight: 300;
        color: #6B7280;
      }
    }
  }

  :global(.react-datepicker) {
    font-family: $fontSerif;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  :global(.react-datepicker__header) {
    background-color: white;
    border-bottom: none;
    padding-top: 0;
  }

  :global(.react-datepicker__month-container) {
    padding: 20px 8px 8px 8px;
  }

  :global(.react-datepicker__month-wrapper) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin: 8px 0;
    justify-items: center;
  }

  :global(.react-datepicker__month) {
    margin: 0;
    padding: 12px;
    width: auto;
    display: inline-block;
    text-align: center;
    color: #374151;
    font-size: 14px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  :global(.react-datepicker__month-text) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px !important;
    height: 45px !important;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    font-size: 14px;
    color: #374151;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: rgba(32, 218, 204, 0.1);
      cursor: pointer;
    }
  }

  :global(.react-datepicker__month--selected) {
    background-color: #20dacc;
    color: white;

    &:hover {
      background-color: #20dacc;
    }
  }

  :global(.react-datepicker__month .react-datepicker__month-text) {
    width: 3rem;
  }

  :global(.react-datepicker__month-text--selected),
  :global(.react-datepicker__month-text--keyboard-selected) {
    background-color: #20dacc !important;
    color: white !important;

    &:hover {
      background-color: #20dacc !important;
    }
  }

  :global(.react-datepicker__month--keyboard-selected) {
    background-color: #20dacc;
    color: white;
  }

  :global(.react-datepicker__navigation) {
    top: 8px;

    &--previous {
      left: 8px;
    }

    &--next {
      right: 8px;
    }
  }

  :global(.react-datepicker__navigation-icon) {
    &::before {
      border-color: #6B7280;
      border-width: 2px 2px 0 0;
    }
  }

  :global(.react-datepicker__current-month) {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    padding: 8px 0;
    margin-bottom: 8px;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  .label {
    margin-bottom: 8px;
    font-family: $fontSerif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #000;
    white-space: nowrap;
  }
}

.clearContainer {
  display: none;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  @media #{$medium} {
    display: flex;
  }
}

.clearText {
  margin-top: 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #909fb7;
  text-align: center;
  text-transform: uppercase;
}

.trashIcon {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  color: #909fb7;
  fill: none;
}

