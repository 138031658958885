@import '~styles/common';

.searchSelect {
  display: none;
  width: 200px;
  max-width: 200px;

  @media #{$medium} {
    display: block;
  }
}

.searchLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchLabel {
  margin-bottom: 8px;
  font-family: $fontSerif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #000;
}

.clearContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.trashIcon {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  color: #909fb7;
  fill: none;
}

.clearText {
  margin-top: 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #909fb7;
  text-align: center;
  text-transform: uppercase;
}

.select {
  height: 50px;
  margin-bottom: 20px;
  font-family: $fontSerif;
  font-size: 14px;
  font-weight: 300;
  background-color: $dirtyWhite;

  input {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
  }

  &:active,
  &:focus {
    background: #f2fdfc;
    border: 1px solid #20dacc;
    outline: none;
  }

  ::placeholder {
    font-family: $fontSerif;
    font-size: 14px;
    font-weight: 300;
  }

  @media #{$medium} {
    font-size: $fontXs;
    background-color: $dirtyWhite;
  }
}

.styledItem {
  margin: 3px;
  padding: 10px;
  color: #555;
  cursor: pointer;
  border-radius: 3px;
}

.styledItem:hover {
  background: #f2f2f2;
}

/* Customize the label (the container) */
.styledItemDiv {
  position: relative;
  display: block;
  padding-left: 35px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.styledItemDiv input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: #fff;
  border: 2px solid #b0c0d7;
}

/* On mouse-over, add a grey background color */
.styledItemDiv:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.styledItemDiv input:checked ~ .checkmark {
  background-color: $brandGreen;
  border: 2px solid $brandGreen;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  position: absolute;
  display: none;
  content: '';
}

/* Style the checkmark/indicator */
.styledItemDiv .checkmark::after {
  top: 5px;
  left: 9px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.styledItemDiv input:checked ~ .checkmark::after {
  display: block;
}

.styledItemDiv .label {
  line-height: 25px;
}
