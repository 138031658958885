@import '~styles/common';

.description {
  @include paragraph;

  margin-bottom: 40px;
  color: $white;
  text-align: center;
}

.linkWrapper {
  text-align: center;
}

.linkButton {
  composes: button light from '~components/Button/Button.module.scss';
}
