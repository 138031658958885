@import '~styles/common';

$defaultPadding: 20px;

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  padding: $defaultPadding;
  color: $black;
  background-color: $white;
  border: 2px solid transparent;
  border-bottom: 8px solid transparent;
  transition: border-color 0.2s ease-in-out;
  place-content: space-between;

  &:hover {
    border-color: $brandGreen;
  }

  @media #{$medium} {
    flex-direction: row;
    padding: $defaultPadding * 2 $defaultPadding * 2 32px;
  }
}

.jobOfferDetails {
  display: flex;
  flex-direction: column;
}

.jobOfferTitle {
  @include titleSecondary;
}

.jobOfferInstitutionName {
  @include paragraph;

  padding: 36px 0;
}

.locationAndDeadline {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media #{$medium} {
    align-items: center;
    flex-direction: row;
  }
}

.applicationDeadlineCopy {
  padding-right: 5px;
  font-weight: $fontRegular;
}

.jobOfferDeadline {
  @include paragraph;

  display: block;
  font-size: $fontXs;

  @media #{$medium} {
    display: flex;
    align-items: center;
    font-size: $fontXs;
  }
}

.jobOfferLocation {
  display: flex;
  align-items: center;
  margin-right: 36px;
  margin-bottom: 12px;

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.jobOfferCreatedAt {
  display: flex;
  margin-bottom: 24px;
}

.icon {
  @include square(16px);

  margin-right: 8px;
  color: $brandGreen;
}

.daysUntilCount {
  margin-left: 5px;
  font-size: $fontXs;
  font-weight: $fontRegular;
  color: $darkGreen;
  text-transform: none;
}
