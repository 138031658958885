@import '~styles/common';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $dirtyWhite;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 250px;
}
