@import '~styles/common';

.imageUploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.imageContainer {
  width: 100%;
  max-width: 300px;
  height: 180px;
  border: 2px dashed #E5E7EB;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dirtyWhite;
  cursor: pointer;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  @media #{$medium} {
    max-width: 400px;
    height: 220px;
  }

  &:hover {
    border-color: #9CA3AF;
    background-color: #f9fafb;
  }
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #9CA3AF;
  font-size: 14px;
  text-align: center;

  .cameraIcon {
    width: 32px;
    height: 32px;
    color: #9CA3AF;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  @media #{$medium} {
    flex-direction: row;
  }
}

.hiddenInput {
  display: none;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: #003B39;
  color: $white;
  border: none;
  font-family: $fontMain;
  font-size: 14px;
  cursor: pointer;
  width: 200px;
  transition: background 0.25s ease-in-out;

  &:focus {
    outline: 2px solid #20dacc;
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
