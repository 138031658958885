@import '~styles/common';

.row {
  @include row;

  @media #{$small} {
    flex-direction: column;
    max-width: 50%;
  }

  @media #{$medium} {
    flex-direction: row;
    max-width: 100%;
  }
}

.paragraph {
  @include paragraphLanding;

  @media #{$small} {
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  @media #{$medium} {
    @include halfWidthContainer;

    &:last-of-type {
      margin-bottom: 60px;
    }
  }
}

.bold {
  font-weight: $fontRegular;
}

.heroSection {
  .heroParagraph {
    @media #{$small} {
      padding-right: $landingCentralSpace;
    }
  }

  @media #{$small} {
    &:nth-of-type(odd) {
      padding-right: 0;
    }
  }
}

.heroPhoto {
  background-size: cover;

  @media #{$small} {
    height: 560px;
  }

  @media #{$medium} {
    height: 422px;
  }
}

.heroButton {
  min-width: 228px;
  margin-right: 20px;

  @media #{$small} {
    min-width: auto;
  }
}
