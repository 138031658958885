@import '~styles/common';

$spacing: 40px;

.page {
  @include container;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.iconWrapper {
  @include square(100px);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brandGreen;
  border-radius: 50%;

  @media #{$medium} {
    @include square(160px);

    margin-bottom: $spacing;
  }
}

.icon {
  width: 50px;

  @media #{$medium} {
    width: 78px;
  }
}

.title {
  @include titleMainCenter;

  max-width: 588px;

  @media #{$medium} {
    margin-bottom: 20px;
  }
}

.description {
  margin-bottom: $spacing;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 22px;
}
