@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~../../../../assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~../../../../assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 80px 40px;
  color: white;

  @media #{$medium} {
    margin-left: 120px;
    padding: 160px 0 20px;
  }
}

.headingTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.headingSubtitle {
  padding-bottom: 20px;
  font-size: $fontL;

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.headingDescription {
  @include paragraph;

  padding-bottom: 20px;
}

.pageTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.pageSubtitle {
  @include paragraph;

  padding-bottom: 40px;
  font-size: $fontXs;

  @media #{$medium} {
    max-width: 60%;
    padding-bottom: 20px;
    font-size: $fontMedium;
  }
}

.emphasized {
  padding: 0 5px;
  font-weight: $fontMedium;
}

.content {
  @include container;
  margin-top: 40px;
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 80px;
  }
}

.mainLayout {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media #{$medium} {
    flex-direction: row;
  }
}

.mainContent {
  flex: 1;
}

.postCard {
  background: $white;
  padding: 24px;
  margin-bottom: 20px;
  border: 2px solid transparent;
  transition: border-color $duration $spring;

  &:hover {
    border-color: $brandGreen;
  }

  p {
    @include paragraph;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.postMeta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fontXxs;
  color: $slategray;
}

.author {
  font-weight: $fontMedium;
}
