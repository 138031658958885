@import '~styles/common';

.backgroundContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
  color: $darkGreen;

  @media #{$medium} {
    padding: 40px;
    background-color: $brandGreenTransparent;
    border: 1px solid $brandGreen;
  }

  @media #{$xlarge} {
    flex-direction: column;
    max-width: 282px;
    margin-bottom: 0;
    margin-left: 25px;
  }
}

.rotated {
  @media #{$medium} {
    padding: 20px;
  }

  @media #{$xlarge} {
    flex-direction: column;
    padding: 60px 40px 40px;
  }
}

.icon {
  flex-shrink: 0;
  width: 36px;
  height: 40px;

  @media #{$small} {
    width: 46px;
    height: 50px;
  }

  @media #{$medium} {
    width: 56px;
    height: 60px;
  }
}

.title {
  margin-left: 20px;
  font-size: $fontS;
  font-weight: $fontBold;
  line-height: 20px;

  @media #{$small} {
    font-size: $fontS;
    line-height: 24px;
  }

  @media #{$medium} {
    margin-bottom: 5px;
  }

  @media #{$xlarge} {
    max-width: 130px;
    margin: 40px auto 20px;
    font-size: $fontS;
    line-height: 24px;
    text-align: center;
  }
}

.description {
  display: none;
  max-width: 600px;
  margin-left: 20px;
  font-size: $fontXxs;
  line-height: 16px;

  @media #{$small} {
    display: block;
  }

  @media #{$xlarge} {
    max-width: auto;
    margin-left: 0;
    text-align: center;
  }
}

.descriptionStrong {
  font-weight: $fontBold;
}
