@import '~styles/common';

$transition: 0.2s ease-in-out;

.footerWrapper {
  width: 100%;
  padding-bottom: 40px;
  @media #{$small} {
    padding: 80px 0;
  }
}

.footerContainer {
  @include container;
  position: relative;
  padding-top: 130px;
  @media #{$small} {
    padding-top: 0;
  }
}

.footerRow {
  display: grid;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;
  @media #{$small} {
    grid-column-gap: 80px;
    grid-template-columns: 1fr 1fr;
  }
  @media #{$medium} {
    grid-template-columns: 0.75fr 1fr 1fr;
  }
}

.footerColumn {
  display: grid;
  row-gap: 20px;
  @media #{$small} {
    grid-template-rows: min(37px) auto;
    row-gap: 40px;
  }
}

.textContainer {
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  line-height: 26px;
  color: $slategray;
  @media #{$medium} {
    grid-row-end: 3;
    grid-row-start: 2;
  }
}

.footerTitle {
  font-size: $fontL;
  font-weight: $fontMedium;
  line-height: 24px;
  @media #{$small} {
    font-size: 28px;
    line-height: 33px;
    grid-row-start: 1;
  }
}

.socialIconsContainer {
  composes: textContainer;
  display: flex;
}

.socialIconItem {
  @include square(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: $lightGray;
  border-radius: 2px;
  transition: background-color $transition;
  &:last-of-type {
    margin: 0;
  }
  &:hover,
  &:focus {
    background-color: $slategray;
  }
}

.socialIconSvg {
  @include square(20px);
}

.linkItem {
  display: flex;
  align-items: center;
  font-family: $fontMain;
  font-weight: $fontRegular;
  color: $black;
  &::before {
    position: relative;
    width: 10px;
    height: 1px;
    margin-right: 14px;
    background-color: $brandGreen;
    content: '';
    transition: width $transition;
  }
  &:hover,
  &:focus {
    &::before {
      width: 25px;
    }
  }
}

.grantsAndEventsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px -20px 20px;
  padding: 20px;
  border-top: 1px solid $xLightGray;
  border-bottom: 1px solid $xLightGray;
  text-justify: auto;
  @media #{$small} {
    flex-direction: row;
    justify-content: space-between;
  }
}

.grantsOrEventsColumn {
  flex: 1;
  font-family: $fontSerif;
  font-size: $fontXxs;
  line-height: 16px;
  color: $slategray;
}

.forumEventsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.mszForumLogo {
  width: auto;
  height: 60px;
  @media #{$small} {
    height: 80px;
  }
}

.footerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media #{$small} {
    flex-direction: row;
  }
}

.foundationLogo {
  position: absolute;
  top: 40px;
  left: 20px;
  width: 280px;
  fill: $black;
  @media #{$small} {
    position: static;
    width: auto;
    height: 24px;
  }
}
