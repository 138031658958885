@import '~styles/common';

.container {
  max-width: $avatarFormWidth;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.instructions {
  margin-top: 40px;
  line-height: 22px;
  text-align: center;
}

.instructionsTitle {
  display: block;
  font-size: $fontS;
  font-weight: $fontBold;
}

.instructionsText {
  display: block;
  font-size: $fontXs;
}

.file {
  margin: 20px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  @media #{$small} {
    flex-direction: row;
  }
}

.button {
  width: 100%;

  @media #{$small} {
    width: 130px;
  }
}

.submit {
  composes: button;
}
