@import '~styles/common';

.reportModal {
  @media #{$small} {
    width: 100%;
    max-width: 600px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.description {
  @include paragraph;
  margin-bottom: 24px;
  color: $slategray;
  font-size: $fontXs;

  @media #{$medium} {
    font-size: $fontS;
  }
}

.actions {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 32px;
  gap: 16px;

  @media #{$medium} {
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
  }
}

.cancelButton,
.submitButton {
  width: 100%;
  height: 48px;

  @media #{$medium} {
    width: auto;
    min-width: 160px;
  }
}

.submitButton {
  background-color: $brandGreen;
  color: $white;

  &:hover:not(:disabled) {
    background-color: $darkGreen;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
