@import '~styles/common';

.button {
  display: inline-flex;
  align-items: center;
  max-width: 300px;
  padding: 20px 30px;
  color: white;
  background: $black;
}

.poloniumLogo {
  width: 136px;
  height: 32px;
}

.arrowIcon {
  width: 32px;
  height: 16px;
  margin-left: 30px;
}
