@import '~styles/common';

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 8px;
  font-family: $fontMain;
  color: $black;
  background-color: $brandGreen;
  border-radius: 4px;
  transform: translateY(-150%);
}
