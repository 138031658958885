@import '~styles/common';

.whiteBox {
  &:not(.landing) {
    &:first-of-type {
      margin-top: -155px;
    }
  }

  @media #{$medium} {
    padding: 40px;
  }
}

.title {
  @include titleSecondary;

  &.warning {
    &::before {
      background-color: $red;
    }
  }
}

.text {
  margin: 20px 0;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 24px;

  @media #{$small} {
    margin: 40px 0;
  }
}
