@import '~styles/common';

$btnSpacing: 10px;
$toastSpacing: 20px;

.list {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $toastZIndex;
}

.listItem {
  box-shadow: $boxShadowTop;
}

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $toastSpacing $toastSpacing $toastSpacing ($toastSpacing * 2);
  font-family: $fontSerif;
  font-size: $fontS;
  line-height: 24px;
  color: $white;
  background-color: $success;
}

.error {
  background-color: $error;
}

.button {
  @include iconBtnHoverEffect(40px);

  align-self: flex-start;
  margin-left: $btnSpacing;
  padding: $btnSpacing;
}

.icon {
  @include square(20px);

  color: $white;
}
