@import '~styles/common';

.boxWithTags {
  composes: boxWithTags from '../common.module.scss';
}

.boxHeader {
  composes: boxHeader from '../common.module.scss';
}

.boxText {
  composes: boxText from '../common.module.scss';
}

.headerTitle {
  @include titleSecondary;
}

.educationTag {
  &:last-of-type {
    margin-bottom: 16px;
  }
}

.container {
  composes: containerWithBorder from '../common.module.scss';
}

.title {
  composes: greenTitle from '../common.module.scss';

  @media #{$medium} {
    &::after {
      position: relative;
      flex: 1;
      height: 1px;
      margin-left: 12px;
      background-image: linear-gradient(
        90deg,
        $brandGreen 20px,
        $xLightGray 21px,
        $xLightGray 100%
      );
      content: '';
    }
  }
}

.formRow {
  position: relative;
}
