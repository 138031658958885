@import '~styles/common';

$spacing: 40px;
$left: $spacing / 2;
$lineWidth: 2px;

.tickIcon {
  width: 20px;
  height: 16px;
}

.circle {
  @include square($spacing);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fontS;
  font-weight: $fontBlack;
  line-height: 16px;
  color: $white;
  border: 4px solid $brandGreen;
  border-radius: 100%;

  &.nextStep {
    @include square($spacing + $lineWidth);

    border: $lineWidth solid $chathamsGreen;
  }

  .active:not(:last-of-type) & {
    &::after {
      position: absolute;
      top: 50%;
      right: -25px;
      z-index: 1;
      width: 25px;
      height: $lineWidth;
      background-color: $brandGreen;
      content: '';

      @media #{$medium} {
        top: 100%;
        left: 50%;
        width: $lineWidth;
        height: 16px;
      }
    }
  }
}

.stepTitle {
  margin-top: 15px;
  font-size: $fontXxs;
  font-weight: $fontLight;
  line-height: 16px;
  color: $white;

  .active & {
    font-weight: $fontBlack;
  }

  @media #{$medium} {
    margin-top: 10px;
    font-size: $fontXs;
    font-weight: $fontBlack;
  }
}

.stepDescription {
  max-width: 260px;
  margin-top: 15px;
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontLight;
  line-height: 16px;
  color: $white;

  @media #{$medium} {
    max-width: 100%;
    margin-top: 20px;
    opacity: 0.7;
  }
}

.textContainer {
  @media #{$medium} {
    max-width: 145px;
    margin-left: 20px;
  }
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-right: 20px;

  &::before {
    position: absolute;
    top: $left;
    left: 40px;
    width: calc(100% - 40px);
    height: $lineWidth;
    background-color: $chathamsGreen;
    content: '';

    @media #{$medium} {
      top: 0;
      left: $left;
      width: $lineWidth;
      height: $spacing;
    }
  }

  &.done {
    &::before {
      background-color: $brandGreen;
    }
  }

  &.active {
    &::before {
      background-color: $brandGreen;
    }

    &::after {
      position: absolute;
      top: $left;
      left: 40px;
      width: calc(100% - 40px);
      height: $lineWidth;
      background-color: $chathamsGreen;
      content: '';

      @media #{$medium} {
        top: auto;
        bottom: 0;
        left: $left;
        width: $lineWidth;
        height: calc(100% - 2 * #{$spacing});
      }
    }

    @media #{$medium} {
      padding: 36px 0;

      & + .step {
        padding-top: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  &:first-of-type {
    min-width: 85px;

    @media #{$medium} {
      padding-top: 0;

      &::before {
        display: none;
      }
    }
  }

  &:last-of-type {
    padding-right: 0;

    &::before,
    &::after {
      display: none;
    }

    @media #{$medium} {
      &::before {
        display: block;
      }
    }
  }

  @media #{$xsmall} {
    padding-right: 40px;
  }

  @media #{$medium} {
    flex-direction: row;
    padding: 38px 0 0;
  }
}
