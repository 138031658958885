@import '~styles/common';

.form {
  composes: authLayoutContent from '../common.module.scss';
}

.button {
  justify-content: center;
  min-width: 180px;
  margin-top: 20px;
}

.link {
  position: relative;
  z-index: 1;
  float: right;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  color: $black;
}
