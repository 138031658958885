@import '~styles/common';

.list {
  @include paragraph();

  margin-bottom: 40px;
  line-height: 32px;
  list-style-type: none;
  list-style-position: inside;
  counter-reset: item;

  @media #{$medium} {
    font-size: $fontS;
    line-height: 32px;
  }
}

.listItem {
  counter-increment: item;

  &::before {
    display: inline-block;
    padding-right: 8px;
    font-weight: bold;
    content: counter(item) '.';
  }
}

.resendButton {
  display: inline-flex;
  align-items: center;
  color: $darkGreen;
  transition: color $buttonTransition;

  &:disabled {
    color: $lightGray;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    color: $brandGreen;
    cursor: pointer;
  }
}

.buttonText {
  flex-shrink: 0;
}

.spinner {
  margin: 8px 0 0 20px;
}
