@import '~styles/common';

.container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: $fontSerif;
  font-size: $fontS;
  font-weight: $fontLight;
  color: $black;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.icon {
  @include square(20px);
}

.iconContainer {
  @include square(50px);

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: $black;
  border-radius: 2px;
}
