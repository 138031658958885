@import '~styles/common';

.row {
  @include row;

  align-items: flex-start;
  margin-top: 40px;

  @media #{$xlarge} {
    flex-direction: row-reverse;
    margin-top: 70px;
  }
}

.subtitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.subtitle {
  @include subtitle;

  @media #{$medium} {
    margin-top: 2px;
  }
}

.privacyTitle {
  font-size: $fontS;
  font-weight: $fontMedium;
  line-height: 19px;
}

.privacyDescription {
  margin: 10px 0 4px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: 24px;
}

.button {
  min-width: 180px;
  margin: 0 20px 20px;
}

.submitButton {
  composes: button;
  margin: 0 0 20px;

  @media #{$small} {
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.bottomContainer {
  margin-top: 40px;
}

.affiliationContainer {
  margin: 42px 0 40px 20px;
  padding-left: 40px;
  border-left: 1px solid $brandGreen;
}
