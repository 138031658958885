@import '~styles/common';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.icon {
  margin-bottom: 30px;
}

.title {
  margin-bottom: 10px;
  font-size: $fontXl;
  line-height: 33px;
}

.strong {
  color: $darkGreen;
}

.formatsHeader {
  display: block;
  margin-top: 10px;
}

.formats {
  display: block;
  font-weight: $fontRegular;
}
