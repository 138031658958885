@import '~styles/common';

.confirmation {
  display: block;
  margin-top: 20px;
  font-size: $fontS;
  font-weight: $fontBold;
}

.button {
  margin-top: 20px;
}
