@import '~styles/common';

.container {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: $actionsZIndex;
  display: block;
  width: 150px;
  padding: 20px 0;
  color: transparent;
  background-color: $catskillWhite;
  transform: scale(1, 0);
  box-shadow: $boxShadow;
  transition: transform $shortDuration ease-in-out, color $shortDuration;
  transform-origin: top center;

  &.open {
    color: $black;
    transform: scale(1);
  }
}

.dropdownButton {
  min-height: 0;
  margin: 0;
  padding: 10px;

  &:not(:last-child) {
    @media #{$small} {
      margin: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $pureBlack;
  opacity: 0.6;
  animation: fadeIn $duration $spring;

  @media #{$medium} {
    display: none;
    pointer-events: none;
  }
}

.overlayTransparent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: none;
}
