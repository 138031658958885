@import '~styles/common';

.searchBar {
  flex-grow: 1;
}

.searchLabel {
  display: none;
  margin-bottom: -12px;
  font-family: $fontSerif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #000;

  @media #{$medium} {
    display: block;
  }
}

.searchField {
  font-size: $fontXxs;
  border-color: $white;

  &:active,
  &:focus {
    background: $dirtyWhite;
    border-color: $white;
  }

  @media #{$medium} {
    font-size: $fontXs;
  }
}

.searchWrapper {
  margin-top: 0;
  margin-bottom: 0;

  label {
    display: none;
  }
}

.searchBarContainer {
  display: flex;
  margin-top: 20px;
  border: 1px solid #b9c7dc;
}

.searchIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  //margin-top: 20px;
  padding-left: 20px;
  background-color: $dirtyWhite;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 60px;
  margin-top: 34px;
  padding: 18px 22px;
  background-color: $dirtyWhite;

  &:is(:disabled) {
    background-color: $dirtyWhite;
  }

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      background-color: $dirtyWhite;
    }
  }
}

.searchIcon {
  height: auto;
  margin: 0;
}

.clearContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
}

.trashIcon {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  color: #909fb7;
  fill: none;
}

.clearText {
  margin-top: 6px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #909fb7;
  text-align: center;
  text-transform: uppercase;
}
