@import '~styles/common';

.wrapper {
  min-height: 100vh;
  background-color: $lightBlue;
}

.headingContainer {
  @include backgroundWithPhoto;

  background-image: url('~assets/images/job-offers-background.svg');

  @media #{$medium} {
    background-image: url('~assets/images/job-offers-background.svg');
  }
}

.headingContentWrapper {
  @include container;

  padding: 80px 40px;
  color: white;

  @media #{$medium} {
    max-width: 45%;
    margin-left: 120px;
    padding: 160px 0 20px;
  }
}

.headingTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.headingSubtitle {
  padding-bottom: 20px;
  font-size: $fontL;

  @media #{$medium} {
    font-size: $fontXl;
  }
}

.headingDescription {
  @include paragraph;

  padding-bottom: 80px;
}

.postOfferButton {
  min-height: 60px;
  padding: 22px 40px;
  font-size: $fontXs;
  font-weight: $fontMedium;
  line-height: 1;
  letter-spacing: 1px;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  background-color: $brandGreen;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $xGreenBlack;
    }
  }
}

.grants {
  @include container;

  margin-top: 40px;

  @media #{$medium} {
    min-height: 100vh;
    margin-top: 60px;
  }
}

.grantsPagination {
  width: 90%;
  margin: 26px auto;

  @media #{$medium} {
    width: 35%;
  }
}

.pageTitle {
  @include titleMain;

  padding-bottom: 20px;

  @media #{$medium} {
    padding-bottom: 40px;
  }
}

.pageSubtitle {
  @include paragraph;

  padding-bottom: 40px;
  font-size: $fontXs;

  @media #{$medium} {
    max-width: 60%;
    padding-bottom: 80px;
    font-size: $fontMedium;
  }
}

.emphasized {
  padding: 0 5px;
  font-weight: $fontMedium;
}

.searchP {
  font-family: $fontRegular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #000;
}

.stepTitle {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: $fontBold;
}

.stepContent {
  font-family: $fontMain;
  font-size: 14px;
  font-weight: $fontRegular;
}

.mask {
  box-shadow: 0 0.5em 3em rgba(0, 0, 0, 1) !important;
}

.popupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  cursor: pointer;
  gap: 12px;
}

.popupText {
  font-family: $fontMain;
  font-size: 16px;
  font-weight: $fontBold;
}
