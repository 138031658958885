@import '~styles/common';

$spacing: 40px;

.container {
  width: 100%;
  padding: 40px 20px;
  text-align: left;

  background: {
    position: center bottom;
    color: $black;
    size: cover;
    image: url('~assets/images/background-mobile.svg');
    repeat: no-repeat;
  }

  @media #{$small} {
    padding-left: $spacing * 2;
  }

  @media #{$medium} {
    width: auto;
    margin: 100px 0 $spacing $spacing / 2;
    padding: 0;
    background: none;

    background: {
      color: transparent;
      image: none;
    }
  }

  @media #{$large} {
    margin-left: $spacing;
  }
}

.help {
  display: none;
  max-width: 200px;
  margin-top: 80px;

  @media #{$medium} {
    display: block;
  }
}

.helpTitle {
  $font-weight: $fontBold;

  margin-bottom: 10px;
  font-family: $fontSerif;
  font-size: $fontS;
  color: $white;
}

.helpDescription {
  font-family: $fontSerif;
  font-size: $fontXxs;
  font-weight: $fontLight;
  line-height: 16px;
  color: $white;
}

.mail {
  display: block;
  font-family: $fontMain;
  font-weight: $fontBold;
  line-height: 16px;
  color: $brandGreen;
}

.stepsContainer {
  display: flex;
  justify-content: space-between;

  @media #{$medium} {
    flex-direction: column;
  }
}
