@import '~styles/common';

$navButtonHeight: 40px;
$sidebarWidth: 33%;

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $navZIndex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  background: {
    color: $black;
    size: cover;
    image: url('~assets/images/auth-nav-background.svg');
    repeat: no-repeat;
  }

  @media #{$xsmall} {
    padding: 20px 40px;
  }

  @media #{$small} {
    padding: 20px 80px;
  }

  @media #{$medium} {
    position: absolute;
    justify-content: flex-end;
    margin-left: $sidebarWidth;
    padding: 40px;

    background: {
      color: transparent;
      image: none;
    }
  }
}

.text {
  display: none;
  margin-right: 20px;
  font-family: $fontSerif;
  font-weight: $fontLight;

  @media #{$medium} {
    display: inline-block;
  }
}

.linkButton {
  min-height: $navButtonHeight;
  padding: 10px 30px;
  color: $dirtyWhite;

  &:hover {
    color: $dirtyWhite;
  }

  @media #{$medium} {
    color: $black;

    &:hover {
      color: $black;
    }
  }
}
