@import '~styles/common';

.subtitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 60px;

  @media #{$medium} {
    margin-top: 0;
  }
}

.subtitle {
  @include subtitle;

  @media #{$medium} {
    margin-top: 2px;
  }
}

.affiliationContainer {
  margin: 42px 0 40px 20px;
  padding-left: 40px;
  border-left: 1px solid $brandGreen;
}
