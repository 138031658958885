@import '~styles/common';

.form {
  background: $white;
  padding: 2rem 1rem;
  max-width: 800px;
  margin: -100px auto 40px;

  border-radius: 0;
  box-shadow: none;

  @media #{$medium} {
    padding: 2rem;
  }
}

.formTitle {
  font-family: $fontMain;
  font-size: 24px;
  font-weight: $fontBold;
  margin-bottom: 2rem;
  color: $xBlack;

  border-left: 4px solid #20dacc;
  padding-left: 1rem;
}

.formField {
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-family: $fontSerif;
    font-size: 14px;
    font-weight: 400;
    color: $xBlack;
  }

  input:not([type="checkbox"]), textarea {
    width: 100%;
    height: 48px;
    padding: 12px;
    border: 1px solid #E5E7EB !important;
    font-family: $fontSerif;
    font-weight: $fontLight;
    font-size: 14px;
    color: #6B7280;

    &::placeholder {
      color: #9CA3AF;
      font-weight: $fontLight;
    }

    &:focus {
      border-color: #20dacc;
      outline: none;
    }
  }

  textarea {
    height: 120px;
    resize: vertical;
  }
}

.clearButton {
  background: none;
  border: none;
  color: #6B7280;
  font-size: 12px;
  cursor: pointer;
  float: right;
  margin-top: -24px;
  padding: 4px 8px;

  &:hover {
    color: #374151;
  }
}

.highlightSection {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.highlightCheckbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #E5E7EB;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:checked {
    background: #20DACC;
    border-color: #20DACC;

    &::after {
      content: '';
      position: absolute;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.highlightLabel {
  font-family: $fontMain;
  font-size: 14px;
  color: #374151;
  cursor: pointer;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: #003B39;
  color: white;
  border: none;
  font-family: $fontMain;
  font-size: 14px;
  cursor: pointer;
  width: 200px;

  &:hover {
    background: darken(#003B39, 5%);
  }

  &.secondary {
    background: white;
    border: 1px solid #E5E7EB;
    color: #374151;

    &:hover {
      background: #F9FAFB;
    }
  }
}

.formActions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 2rem;

  @media #{$medium} {
    flex-direction: row;
  }
}

.cancelButton {
  margin-bottom: 0 !important;
}

.saveButton {

}
