@import '~styles/common';

$spacing: 10px;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  margin-top: $spacing;
  margin-left: (-$spacing);

  @media #{$xxsmall} {
    margin-top: 0;
  }

  @media #{$medium} {
    margin-left: auto;
  }
}

.hint {
  padding-left: $spacing;
  font-size: $fontXxs;
  font-style: italic;
  line-height: 14px;
}

.button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing;
  font-size: $fontXxxs;
  font-weight: $fontRegular;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;

  @media #{$medium} {
    width: auto;
    font-size: $fontXxs;
  }
}

.icon {
  @include square(16px);

  margin-right: 8px;
}
