$highlighted-border-width: 4px;
$line-height: 20px;

@mixin inputActive {
  background: $brandGreenTransparent;
  outline: none;

  border: {
    width: 1px;
    color: $brandGreen;
    right-width: $highlighted-border-width;
    style: solid;
  }
}

@mixin input {
  padding: 14px 20px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: $line-height;
  color: $inputText;
  background: $dirtyWhite;
  border: 1px solid $inputBorder;
  transition: border $duration $spring, background $duration $spring;

  &::placeholder {
    color: $textPlaceholder;
  }

  &:active,
  &:focus {
    @include inputActive;
  }
}

@mixin inputValid {
  border: {
    right-color: $brandGreen;
    right-width: $highlighted-border-width;
  }
}

@mixin inputInvalid {
  color: $error;
  background: $errorBackground;

  border: {
    color: $error;
    right-width: $highlighted-border-width;
  }

  &:focus,
  &:active {
    color: $inputText;
  }
}

@mixin label {
  display: block;
  margin-bottom: 8px;
  font-size: $fontS;
  line-height: $line-height;
  color: $labelText;
}

@mixin reactSelect {
  &__indicators {
    display: none;
  }

  &__control {
    @include input;

    padding: 10px 18px;
    font-weight: $fontLight;
    border-radius: 0;

    &:active,
    &:focus {
      @include inputActive;
    }

    &--is-focused {
      @include inputActive;

      box-shadow: none;

      &:hover {
        @include inputActive;
      }
    }
  }

  &__placeholder {
    font-family: $fontSerif;
    font-weight: $fontLight;
    color: $textPlaceholder;
  }

  &__menu {
    position: relative;
    z-index: $autocompleteZIndex;
    margin-top: 0;
    border-color: $xLightGray;
    border-radius: 0;
    box-shadow: 0 8px 12px 0 rgba($xBlack, 0.12);

    &-list {
      padding: 0;
    }
  }

  &__option {
    padding: 12px 20px;
    font-family: $fontSerif;
    font-size: $fontXs;
    font-weight: $fontLight;
    line-height: 26px;
    cursor: pointer;

    &:active {
      color: $black;
      background-color: $brandGreenTransparent;
    }

    &--is-focused {
      background-color: $jaki;

      &:active,
      &:focus {
        color: $black;
        background-color: $brandGreenTransparent;
      }
    }

    &--is-selected {
      color: $black;
      background-color: $brandGreenTransparent;
    }
  }

  &__value-container {
    padding: 0;
    overflow: visible;

    &--has-value & {
      @include inputActive;

      overflow: hidden;
    }

    &--is-multi {
      font-family: $fontMain;
      font-weight: $fontRegular;

      &.react-select__value-container--has-value {
        margin: -8px;
      }
    }
  }

  &__input input {
    font-weight: $fontLight;
  }
}
