@import '~styles/common';

.formWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
}

.sendButton {
  margin-left: 20px;
}
