@import '~styles/common';

.container {
  @include square($photoMobileSize);

  position: relative;
  flex-shrink: 0;
  border: 8px solid $brandGreen;
  border-radius: 50%;

  @media #{$xsmall} {
    @include square($photoMediumSize);
  }

  @media #{$medium} {
    @include square($photoSize);
  }
}

.photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
