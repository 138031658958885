@import '~styles/common';

.order-dropdown {
  .dropdown {
    &__indicator {
      z-index: 2;
      padding-left: 4px;
      color: $black;

      &:hover {
        color: $black;
      }

      svg {
        transition: transform 0.2s ease-in-out;
      }

      &-separator {
        display: none;
      }

      @media #{$xsmall} {
        padding-left: 8px;
      }

      @media #{$medium} {
        padding-right: 20px;
      }
    }

    &__control {
      min-width: 140px;
      padding-left: 8px;
      font-size: $fontXs;
      font-weight: $fontBold;
      line-height: 26px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      box-shadow: none;

      &--menu-is-open .dropdown__indicator svg {
        transform: rotate(180deg);
      }

      &--is-disabled {
        background: $white;
      }

      @media #{$medium} {
        padding-left: 4px;
      }

      @media #{$large} {
        min-width: 152px;
      }
    }

    &__single-value {
      margin: 0;
    }

    &__value-container {
      min-width: 98px;
      padding: 0;
    }

    &__menu {
      top: 34px;
      border-radius: 0;
      box-shadow: 0 20px 100px 0 rgba($black, 0.1);

      &-list {
        padding: 0;
      }
    }

    &__option {
      padding: 12px 10px;
      font-family: $fontSerif;
      font-size: $fontXs;
      font-weight: $fontLight;
      line-height: 26px;
      cursor: pointer;

      &:active,
      &:focus {
        background-color: $lightBlue;
      }

      &--is-selected {
        color: $black;
        background-color: $white;
      }

      &--is-focused {
        background-color: $lightBlue;

        &--is-selected {
          background-color: $lightBlue;
        }
      }

      @media #{$xxsmall} {
        font-size: $fontXs;
      }

      @media #{$medium} {
        padding: 12px 0 12px 20px;
      }
    }
  }

  @media #{$medium} {
    margin-left: 4px;
  }
}
