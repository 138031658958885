@import '~styles/common';

.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 26px;
}

.label {
  @include label;
}

.prompt {
  display: block;
  margin: -8px 0 11px;
  font-family: $fontSerif;
  font-size: $fontXs;
  font-weight: $fontLight;
  line-height: $line-height;
}

.textareaWrapper {
  position: relative;
}

.textarea {
  @include input;

  display: block;
  width: 100%;
  min-height: 120px;
  resize: vertical;
  padding: 12px;
  line-height: 1.5;

  &::placeholder {
    color: #9CA3AF;
  }

  &:focus {
    outline: none;
    border-color: #20dacc;
  }
}

.valid {
  @include inputValid;
}

.invalid {
  @include inputInvalid;
}
