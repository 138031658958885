@import '~styles/common';

.field {
  margin-bottom: 1.5rem;

  .label {
    display: block;
    margin-bottom: 8px;
    font-family: $fontSerif;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    color: #000;
  }

  :global {
    .select__control {
      font-family: $fontSerif;
      font-size: 14px;
      font-weight: $fontLight;
      min-height: 48px;
      background: $dirtyWhite;
      border: 1px solid rgb(185, 199, 220);
      border-radius: 2px;
      transition: all 0.2s ease-in-out;

      &:hover {
        border-color: $brandGreen;
      }

      &--is-focused {
        border-color: $brandGreen !important;
        box-shadow: none;
        background: #f2fdfc;
      }
    }

    .select__placeholder {
      color: $mediumGray;
    }

    .select__value-container {
      padding: 2px 16px;
    }

    .select__menu {
      font-family: $fontSerif;
      margin-top: 4px;
      border-radius: 2px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .select__option {
      font-size: 14px;
      padding: 12px 16px;

      &--is-focused {
        background-color: #f2fdfc;
      }

      &--is-selected {
        background-color: $brandGreen;
      }
    }
  }
}

.error {
  margin-top: 4px;
  font-family: $fontSerif;
  font-size: 12px;
  color: $red;
}
